import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { FabProps, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { StyledFab } from '@components/FloatingViewButton/styled';

const FloatingViewButton: FC<FabProps & { locale: string; icon: OverridableComponent<SvgIconTypeMap> }> = ({
  locale,
  icon: Icon,
  size,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <StyledFab {...props} aria-label="view button" size={size} sx={{ boxShadow: 6 }}>
      <Typography fontWeight={500} fontSize={14} mr={1}>
        {t(locale)}
      </Typography>
      <Icon fontSize={size} />
    </StyledFab>
  );
};

export default memo(FloatingViewButton);
