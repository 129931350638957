import { FC, memo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { useUser } from '@hooks/api/useUser';
import { useSubscription } from '@hooks/useSubscription';
import AppLayout from '@providers/layouts/AppLayout';

import Loading from '@components/Loading/Loading';

const PrivateRouteOutlet: FC = () => {
  const { isLoading: isLoadingUser } = useUser();
  const { data: subscriptionData, isLoadingSubscription } = useSubscription();
  const navigate = useNavigate();

  const isLoading = [isLoadingUser, isLoadingSubscription].some(Boolean);

  if (isLoading) return <Loading />;

  if (subscriptionData?.status === 'inactive') {
    navigate(`/${ROUTING.SUBSCRIBE}`, { replace: true });
  }

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

export default memo(PrivateRouteOutlet);
