import { FC, memo } from 'react';
import { Controller, UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyledRegularTextField } from '@pages/styled';

import { InputAdornment, Typography } from '@mui/material';
import { StyledIconButton } from '@components/InputPassword/styled';

const Participant: FC<{ index: number; formLength: number; handleRemove: UseFieldArrayRemove; id: string }> = ({ index, handleRemove, id, formLength = 0 }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={`data.${index}.participantName`}
      render={({ field }) => (
        <StyledRegularTextField
          fullWidth
          label={t('common.inputs.participantName')}
          type="text"
          variant='outlined'
          data-testid={`form-field-${id}-participant`}
          inputProps={{ maxLength: 100 }}
          slotProps={{
            input: {
              endAdornment: formLength > 1 && (
                <InputAdornment position="end">
                  <StyledIconButton onClick={() => handleRemove(index)} disableRipple disableFocusRipple disableTouchRipple>
                    <Typography fontSize={14}>
                      {t('common.remove')}
                    </Typography>
                  </StyledIconButton>
                </InputAdornment>
              ),
            }
          }}
          {...field}
        />
      )}
    />
  )
};

export default memo(Participant);
