import { Alert, AlertProps, Box, BoxProps, Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAlert = styled(Alert)<AlertProps>(() => ({
  marginBottom: '36px',
  borderColor: '#0588D1',
  maxWidth: '830px',
}));

export const StyledEventBox = styled(Box)<BoxProps & { isCustom?: boolean }>(({ theme, isCustom }) => ({
  borderRadius: '5px',
  border: `1px ${isCustom ? 'dashed' : 'solid'} ${theme.colors.primary.main}`,
  backgroundColor: theme.colors.background.detailsBackground,
  padding: '20px 18px',
}));

export const StyledAddButton = styled(Button)(({ theme }) => ({
  color: theme.colors.primary.main,
  padding: 0,
  margin: 0,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const StyledActionsButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'transparent',
  },
  color: theme.colors.primary.main,
  boxShadow: 'none',
  height: 24,
  padding: 0,
}));
