import { FC, memo, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthUserPartialUpdateMutation, useAuthUserParticipantsCreateMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { ROUTING } from '@constants/routing';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUser } from '@hooks/api/useUser';
import { DEFAULT_FORM_VALUES } from '@pages/Relationship/constants';
import { schema } from '@pages/Relationship/schema';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';
import { ObjectSchema } from 'yup';

import { Box } from '@mui/material';

import RelationshipForm from './components/RelationshipForm';
import { FormFields } from './types';

const Relationship: FC = () => {
  const [participantCreation, setParticipantCreation] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const { isLoading: isLoadingUser, userFullName } = useUser();

  const form = useForm<FormFields>({
    resolver: yupResolver(schema as ObjectSchema<FormFields>),
    defaultValues: DEFAULT_FORM_VALUES,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const [userPartialUpdateMutation] = useAuthUserPartialUpdateMutation();
  const [createParticipantMutation] = useAuthUserParticipantsCreateMutation();

  const isLoading = [isLoadingUser, participantCreation].some(Boolean);

  const createParticipants = useCallback(
    async (participantsData: Array<{ participantName: string }>) => {
      const createPromises = participantsData
        .filter(p => p.participantName?.trim())
        .map(p =>
          createParticipantMutation({
            participantRequest: { name: p.participantName.trim() },
          }).unwrap(),
        );

      if (createPromises.length === 0) return;

      try {
        await Promise.all(createPromises);
      } catch (error) {
        throw new Error(`Failed to create participants: ${getErrorMessage(error, API_ERROR_MSG_PATH)}`);
      }
    },
    [createParticipantMutation],
  );

  const onConfirm: SubmitHandler<FormFields> = useCallback(
    async ({ nominee, childRepresentative, participant, data }) => {
      try {
        setParticipantCreation(true);

        await userPartialUpdateMutation({
          patchedCustomUserDetailsRequest: {
            ...(participant && { is_participant: true }),
            ...(nominee && { is_nominee: true }),
            ...(childRepresentative && { is_child_representative: true }),
          },
        }).unwrap();

        if (participant && !nominee && !childRepresentative){
          await createParticipants([{ participantName: userFullName }])
        }

        if (nominee || childRepresentative) {
          await createParticipants(data);
        }

        navigate(ROUTING.ROOT);
      } catch (err) {
        snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      } finally {
        setParticipantCreation(false);
      }
    },
    [createParticipants, navigate, snackbar, userPartialUpdateMutation],
  );

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.ndisRelationship')}</title>
      </Helmet>
      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        alignItems="center"
        aria-label="ndis relationship page"
        pb={20}
      >
        <FormProvider {...form}>
          <Box component="form" onSubmit={form.handleSubmit(onConfirm)}>
            <RelationshipForm loading={isLoading} />
          </Box>
        </FormProvider>
      </Box>
    </>
  );
};

export default memo(Relationship);
