import { useCallback } from 'react';
import { useApiInvoicesUpdatePartialUpdateMutation } from '@api/api';

export const usePartialUpdate = (invoiceId: string | number) => {
  const [invoicePartialUpdateMutation, { isLoading: isLoadingPartialUpdate, error: partialUpdateError }] =
    useApiInvoicesUpdatePartialUpdateMutation();

  const updateInvoice = useCallback(
    async (payload: Record<string, any>) => {
      await invoicePartialUpdateMutation({
        id: +invoiceId,
        patchedInvoiceUpdateRequest: payload,
      });
    },
    [invoiceId, invoicePartialUpdateMutation],
  );

  return {
    updateInvoice,
    isLoadingPartialUpdate,
    partialUpdateError,
  };
};
