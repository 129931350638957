type CombinationKey = `${0 | 1}${0 | 1}${0 | 1}`;

export function getParticipantsLimit(
  isParticipant: boolean,
  isNominee: boolean,
  isRepresentative: boolean
): number {
  const limits: Record<CombinationKey, number> = {
    '000': 0,
    '100': 0,
    '010': 3,
    '001': 3,
    '110': 2,
    '101': 2,
    '011': 3,
    '111': 2,
  };

  return limits[`${+isParticipant}${+isNominee}${+isRepresentative}` as CombinationKey];
}
