import { FC, memo, PropsWithChildren } from 'react';
import { useBreakpoints } from '@hooks/useBreakpoints';

import { Box } from '@mui/material';
import LogoWhite from '@components/LogoWhite';
import LogoWhiteSmall from '@components/LogoWhiteSmall';

import { StyledWrapperContent } from './styled';

const RegistrationView: FC<PropsWithChildren> = ({ children }) => {
  const { downSm } = useBreakpoints();

  return (
    <Box
      sx={{
        background: 'linear-gradient(to top,#FDC15E1F, #F5A57540, #ED898B80, #A354AFBF, #8435E9, #5A1ED3)',
        paddingTop: {
          xs: '50px',
          md: '89px',
          lg: '87px',
        },
        paddingLeft: {
          xs: '16px',
          sm: '20px',
        },
        paddingRight: {
          xs: '16px',
          sm: '20px',
        },
        minHeight: '100vh',
      }}
    >
      <main aria-roledescription="registration-container">
        <StyledWrapperContent>
          <Box
            mb={{
              xs: '45px',
              sm: '47px',
              md: '54px',
              lg: '46px',
            }}
            pl={{
              md: '30px',
              lg: '82px',
            }}
          >
            {downSm ? <LogoWhiteSmall /> : <LogoWhite />}
          </Box>
          {children}
        </StyledWrapperContent>
      </main>
    </Box>
  );
};

export default memo(RegistrationView);
