import {
  Button,
  ButtonProps,
  Paper,
  PaperProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledHint = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  fontSize: '12px',
  color: theme.colors.action.disabled,
  fontFamily: 'Inter',
}));

export const StyledDigitTextField = styled(TextField)<TextFieldProps>(({ theme, error }) => ({
  appearance: 'none',
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& .MuiInputBase-root input': {
    textAlign: 'center',
    height: '100%',
    fontSize: 32,
    fontFamily: 'WFVisualSans',
    color: error ? theme.colors.error.dark : theme.colors.black,
  },
  '&.MuiFormControl-root': {
    display: 'flex',
    justifyContent: 'center',
    width: 61,
    height: 71,
    paddingTop: '12px',
  },
  borderTopRightRadius: '4px',
  borderTopLeftRadius: '4px',
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  borderBottom: `${error ? 2 : 3}px solid ${error ? theme.colors.error.main : theme.colors.primary.dark}`,
  backgroundColor: error ? '#FFEEEF' : '#F4ECFF',
}));

export const StyledTextButton = styled(Button)<ButtonProps>({
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export const StyledPaper = styled(Paper)<PaperProps & { theme?: Theme }>(() => ({
  borderRadius: '10px',
}));
