import { useSearchParams } from 'react-router-dom';

export const useTokenQueryParam = () => {
  const [tokenSearchParams, setTokenSearchParams] = useSearchParams();

  const token = tokenSearchParams.get('token') || '';

  return {
    token,
    removeTokenParam: () => {
      tokenSearchParams.delete('token');
      setTokenSearchParams(tokenSearchParams, { replace: true });
    },
  };
};
