export const USER_ACCESS_TOKEN = 'authToken';
export const USER_REFRESH_TOKEN = 'refreshToken';
export const ERROR = 'error';
export const SUCCESS = 'success';
export const RESEND_TIMER = 60;
export const REFRESH_TOKEN_URL = 'auth/token/refresh/';
export const SIGNUP_EMAIL = 'signup_email';
export const SIGNUP_PASSWORD = 'signup_password';
export const SIGNUP_ACTION = 'signed_up';
export const TERMS_OF_SERVICE_URL = 'https://www.capsure.com.au/terms-of-service';
export const PRIVACY_POLICY_URL = 'https://www.capsure.com.au/privacy-policy';
export const EMAIL_DOMAIN = '@capsure.com.au';
export const CONFIRM_EMAIL_NUMBER_OF_DIGITS = 4;
