import { Box, BoxProps, Button, ButtonProps, Grid, Typography, TypographyProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { GridProps } from '@mui/system';
import styled from '@mui/system/styled';

export const StyledGrid = styled(Grid)<GridProps>({
  '&.MuiGrid-root': { paddingTop: '0px' },
});

export const StyledTypography = styled(Typography)<TypographyProps>({
  wordBreak: 'break-word',
});

export const StyledUploadBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  border: `1px dashed ${theme.colors.primary.main}`,
  backgroundColor: '#FCFAFF',
  width: '543px',
  height: '254px',
  padding: '57px 78px',
}));

export const StyledFileItemBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  border: `1px solid ${theme.colors.primary.main}`,
}));

export const StyledFileItemName = styled(Typography)<TypographyProps>({
  maxWidth: '100%',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
});

export const StyledAddFilesButton = styled(Button)<ButtonProps>({
  padding: '8px 22px',
});
