import { COUNTDOWN_DATE } from '@constants/common';
import { addSeconds, format, parseISO } from 'date-fns';

export const formatCountdownTime = (countdown: number, message: string) => {
  if (countdown === 0) {
    return '0';
  }

  const endDate = addSeconds(COUNTDOWN_DATE, countdown);
  const formattedCountdown = format(endDate, 'mm:ss');

  return `(${message} ${formattedCountdown})`;
};

type Month = 'MM' | 'MMM' | 'MMMM';
type Day = 'd' | 'dd';

export const convertIsoToReadable = (
  isoDate?: string | Date,
  options?: { fullYear?: boolean; withComma?: boolean; monthFormat?: Month; dayFormat?: Day },
) => {
  if (!isoDate) return '';

  const { fullYear = false, withComma = false, monthFormat = 'MMM', dayFormat = 'dd' } = options || {};
  const dateObject = typeof isoDate === 'string' ? parseISO(isoDate) : isoDate;

  return format(dateObject, `${dayFormat} ${monthFormat}${withComma ? ',' : ''} ${fullYear ? 'yyyy' : 'yy'}`);
};

export const formatDateString = (dateString: string): string => {
  const date = parseISO(dateString);
  return format(date, 'yyyy-MM-dd');
};

export const convertIsoToReadableWithTime = (isoDate?: string) => {
  if (!isoDate) return '';
  const dateObject = parseISO(isoDate);
  return format(dateObject, 'dd MMM yyyy, h:mma').replace('AM', 'am').replace('PM', 'pm');
};

export function formatDate(date: Date) {
  return format(date, 'yyyy-MM-dd');
}
