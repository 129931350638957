import styled from '@mui/system/styled';

const SuccessIcon = styled(props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.0003 1.66675C5.40033 1.66675 1.66699 5.40008 1.66699 10.0001C1.66699 14.6001 5.40033 18.3334 10.0003 18.3334C14.6003 18.3334 18.3337 14.6001 18.3337 10.0001C18.3337 5.40008 14.6003 1.66675 10.0003 1.66675ZM8.33366 14.1667L4.16699 10.0001L5.34199 8.82508L8.33366 11.8084L14.6587 5.48341L15.8337 6.66675L8.33366 14.1667Z"
      fill="#2E7D32"
    />
  </svg>
))({});

export default SuccessIcon;
