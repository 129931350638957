import { ROUTING } from '@constants/routing';

export const navConfig = [
  {
    title: 'Dashboard',
    path: ROUTING.ROOT,
  },
  {
    title: 'Invoices',
    path: `/${ROUTING.INVOICES}`,
  },
];
