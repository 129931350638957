import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { useUser } from '@hooks/api/useUser';

import { Box } from '@mui/material';

const ParticipantGuard: FC<PropsWithChildren> = ({ children }) => {
  const { data: user, isLoading } = useUser();
  const hasRepresentative = user?.is_participant || user?.is_nominee || user?.is_child_representative;

  if (isLoading) return null;

  if (user?.is_participant || user?.is_nominee || user?.is_child_representative)
    return <Navigate to={ROUTING.ROOT} replace />;

  return (
    <Box style={hasRepresentative ? { visibility: 'hidden' } : undefined}>{!hasRepresentative ? children : null}</Box>
  );
};

export default ParticipantGuard;
