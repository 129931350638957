import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PreviewDoc } from '@pages/InvoiceDetails/components/DetailsTab/types';

import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, styled, Typography } from '@mui/material';

const ResponsiveBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  flexDirection: 'row',
  width: '100%',
}));

const PreviewControls: FC<PreviewDoc> = ({
  blob,
  fileName,
  buttonHeight,
  fontSize,
  buttonTypeDownload,
  buttonTypeNewWindow,
}) => {
  const { t } = useTranslation();

  const handleDownloadBlob = () => {
    if (blob) {
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}`;
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
  };

  const handleOpenInNewWindow = () => {
    if (blob) {
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
    }
  };

  return (
    <ResponsiveBox>
      <Button
        disableRipple
        disableFocusRipple
        disableTouchRipple
        disabled={!blob}
        variant={buttonTypeDownload}
        color="primary"
        onClick={handleDownloadBlob}
        endIcon={<DownloadIcon />}
        sx={{
          padding: 0,
          height: buttonHeight,
          boxShadow: 'none',
          justifyContent: 'start',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Typography fontWeight={500} fontSize={fontSize}>
          {t('common.download')}
        </Typography>
      </Button>
      <Button
        disableRipple
        disableFocusRipple
        disableTouchRipple
        disabled={!blob}
        variant={buttonTypeNewWindow}
        color="primary"
        endIcon={<OpenInNewIcon />}
        onClick={handleOpenInNewWindow}
        sx={{
          padding: 0,
          height: buttonHeight,
          boxShadow: 'none',
          textWrap: 'nowrap',
          justifyContent: 'start',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Typography fontWeight={500} fontSize={fontSize}>
          {t('common.openInNewWindow')}
        </Typography>
      </Button>
    </ResponsiveBox>
  );
};

export default memo(PreviewControls);
