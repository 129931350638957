import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTextButton = styled(Button)<ButtonProps & { maxWidth?: string }>(({ maxWidth }) => ({
  maxWidth: maxWidth || '100%',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  paddingLeft: 0,
}));
