import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_NOTE_CHARACTERS } from '@constants/common';
import QuillEditor from '@pages/InvoiceDetails/components/NotesTab/components/Editor';
import { StyledBackButton, StyledSaveLoadingButton } from '@pages/InvoiceDetails/components/NotesTab/styled';
import { NoteMod } from '@pages/InvoiceDetails/components/NotesTab/types';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Typography } from '@mui/material';

const ModifyNote: FC<NoteMod> = ({ handleSave, handleCancel, loading, noteText = '' }) => {
  const [textValue, setTextValue] = useState(noteText);

  const handleChange = (html: string) => setTextValue(html);

  const { t } = useTranslation();
  const isError = textValue.length > MAX_NOTE_CHARACTERS;

  return (
    <Box pt={2.5} py={3.5} display="flex" flexDirection="column">
      <StyledBackButton
        onClick={handleCancel}
        color="primary"
        startIcon={<ChevronLeftIcon />}
        sx={{ mb: 3 }}
        variant="text"
      >
        <Typography fontSize={16} fontWeight={500}>
          {t('common.back')}
        </Typography>
      </StyledBackButton>

      {/* Quill component */}
      <Box mb={5}>
        <QuillEditor text={textValue} handleChange={handleChange} />
      </Box>

      <StyledSaveLoadingButton
        color={isError ? 'error' : 'primary'}
        variant="contained"
        loading={loading}
        onClick={() => handleSave(textValue)}
        disabled={isError}
      >
        <Typography fontSize={16} fontWeight={500}>
          {t('common.save')}
        </Typography>
      </StyledSaveLoadingButton>
    </Box>
  );
};

export default memo(ModifyNote);
