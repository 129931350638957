import { FC, memo, useEffect } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import Participant from '@pages/Relationship/components/Participant';
import {
  CHILD_REPRESENTATIVE_CHECKBOX,
  INITIAL_PARTICIPANT,
  NOMINEE_CHECKBOX,
  PARTICIPANT_CHECKBOX
} from '@pages/Relationship/constants';
import { StyledAddParticipantButton, StyledCheckboxFormControlLabel } from '@pages/Relationship/styled';
import { ParticipantData } from '@pages/Relationship/types';
import { getParticipantsLimit } from '@pages/Relationship/utils';
import { StyledBox, StyledPaper } from '@pages/styled';

import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Checkbox, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import RegistrationHeader from '@components/RegistrationHeader';

const RelationshipForm: FC<{ loading?: boolean }> = ({ loading }) => {
  const { t } = useTranslation();
  const { control, formState: { errors }, reset } = useFormContext();

  const { fields, append, remove} = useFieldArray({
    control,
    name: 'data',
    keyName: 'fieldId'
  });

  const [isParticipant, isNominee, isRepresentative, names] = useWatch({
    control,
    name: [PARTICIPANT_CHECKBOX, NOMINEE_CHECKBOX, CHILD_REPRESENTATIVE_CHECKBOX, 'data']
  });

  const participantsLimit = getParticipantsLimit(isParticipant, isNominee, isRepresentative);

  const isCheckboxSelected = [isParticipant, isNominee, isRepresentative].some(Boolean);
  const isMaxParticipantsReached = fields.length >= participantsLimit;
  const areParticipantsFilled = (isNominee || isRepresentative)
    ? names.every((n: ParticipantData) => n.participantName.length > 0)
    : true;


  useEffect(() => {
    if (fields.length > participantsLimit && participantsLimit >= 2) {
      remove(fields.length - 1)
    }
  }, [fields.length, participantsLimit, remove, reset]);

  return (
    <Box display="flex" width="100%" justifyContent="center" flexDirection="column" aria-label="NDIS relationship form">
      <RegistrationHeader />

      <StyledPaper
        elevation={6}
        sx={{
          px: {
            xs: '25px',
            sm: '40px'
          },
          py: '30px',
          width: {
            sm: '560px',
            md: '550px',
            lg: '505px'
          }
        }}
      >
        <StyledBox>
          <Typography fontSize={20} fontFamily="WFVisualSans" mb={3}>
            {t('auth.ndis.formTitle')}
          </Typography>
          <Typography fontSize={14} mb={1}>
            {t('auth.ndis.formSubtitle')}
          </Typography>

          <Box>
            <Typography fontWeight={400} fontSize={14} mb={2}>
              {t('auth.ndis.checkboxTitle')}
            </Typography>
            {/* Checkboxes */}
            <Box display="flex" flexDirection="column" gap={1}>
              <StyledCheckboxFormControlLabel
                control={
                  <Controller
                    control={control}
                    name={PARTICIPANT_CHECKBOX}
                    render={({ field }) => (<Checkbox {...field} />)}
                  />
                }
                labelPlacement="end"
                label={t('auth.ndis.participant')}
              />
              <StyledCheckboxFormControlLabel
                control={
                  <Controller
                    control={control}
                    name={NOMINEE_CHECKBOX}
                    render={({ field }) => (<Checkbox {...field} />)}
                  />
                }
                labelPlacement="end"
                label={t('auth.ndis.nominee')}
              />
              <StyledCheckboxFormControlLabel
                control={
                  <Controller
                    control={control}
                    name={CHILD_REPRESENTATIVE_CHECKBOX}
                    render={({ field }) => (<Checkbox {...field} />)}
                  />
                }
                labelPlacement="end"
                label={t('auth.ndis.childRepresentative')}
              />
            </Box>
          </Box>

          {(isRepresentative || isNominee) && (
            <Box
              mt={4}
            >
              <Typography fontSize={14} mb={1}>
                <Trans
                  i18nKey={t('auth.ndis.addNamesTitle')}
                  values={{ limit: participantsLimit }}
                />
              </Typography>
              <Typography fontSize={14} fontWeight={400} mb={4}>{t('auth.ndis.addNameSubtitle')}</Typography>
              {fields.map((field, index) => (
                <Box key={field.fieldId} my={index > 0 ? 3 : 0} mb={index === fields.length - 1 ? 0 : 3}>
                  <Participant
                    handleRemove={remove}
                    id={field.fieldId}
                    index={index}
                    formLength={fields.length}
                  />
                </Box>
              ))}

              <Box mt={isMaxParticipantsReached ? 1 : 2}>
                {isMaxParticipantsReached &&
                  <Typography
                    fontSize={14}
                    fontWeight={400}
                    color={alpha('#000', 0.6)}
                  >
                    {t('auth.ndis.maxReached')}
                  </Typography>
                }
                {!isMaxParticipantsReached && (
                  <StyledAddParticipantButton
                    variant="text"
                    type="button"
                    onClick={() => {
                      append({ ...INITIAL_PARTICIPANT });
                    }}
                  >
                    <Typography mr={1} color="primary">{t('auth.ndis.add')}</Typography>
                    <AddIcon color="primary" />
                  </StyledAddParticipantButton>
                )}
              </Box>
            </Box>
          )}

          <LoadingButton
            fullWidth
            disabled={!isCheckboxSelected || Object.keys(errors).length > 0 || !areParticipantsFilled}
            variant="contained"
            color="primary"
            loading={loading}
            aria-label="submit ndis relation button"
            type="submit"
            sx={{ mt: 5 }}
          >
            {t('auth.continue')}
          </LoadingButton>
        </StyledBox>
      </StyledPaper>
    </Box>
  );
};

export default memo(RelationshipForm);
