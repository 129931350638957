import { Button, ButtonProps, FormControlLabel, FormControlLabelProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCheckboxFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>({
  position: 'relative',
  width: '70%',
  '& .MuiTypography-root': {
    fontSize: '16px',
    fontWeight: 400,
    position: 'absolute',
    left: '40px',
    textWrap: 'nowrap',
  },
});

export const StyledAddParticipantButton = styled(Button)<ButtonProps>(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: 'transparent',
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}))
