import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import PreviewControls from '@pages/InvoiceDetails/components/DetailsTab/components/PreviewControls';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button } from '@mui/material';

import { StyledDocViewerWrapper, StyledModal } from '../styled';

import '@cyntler/react-doc-viewer/dist/index.css';

export interface BlobDoc {
  blob: Blob;
  fileName: string;
}

const PreviewModal = NiceModal.create(
  ({ docs, selectedPage = 1, isPdf = false }: { docs: BlobDoc[]; selectedPage: number; isPdf: boolean }) => {
    const { t } = useTranslation();
    const modal = useModal();

    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    return (
      <StyledModal open={modal.visible} size="xl" onClose={handleCancelClick}>
        <Box padding="24px 48px" minWidth={560}>
          <Box
            display="flex"
            flexDirection={{ md: 'row', xs: 'column', sm: 'column' }}
            gap={{ md: 0, xs: 2 }}
            justifyContent="space-between"
            height={{ xs: 70, sm: 70, md: 42 }}
            mb="34px"
          >
            <PreviewControls
              blob={docs[0].blob}
              fileName={docs[0].fileName}
              fontSize={16}
              buttonTypeDownload="text"
              buttonTypeNewWindow="text"
              buttonHeight="42px"
            />
            <Button
              color="primary"
              onClick={handleCancelClick}
              endIcon={<CloseIcon />}
              style={{ width: '113px', maxHeight: '42px' }}
            >
              {t('common.close')}
            </Button>
          </Box>
          <Box minHeight={400}>
            <StyledDocViewerWrapper>
              {isPdf ? (
                <Document file={docs[0].blob}>
                  <Page pageNumber={selectedPage} scale={1} />
                </Document>
              ) : (
                <DocViewer
                  pluginRenderers={DocViewerRenderers}
                  documents={docs.map(file => ({
                    uri: window.URL.createObjectURL(file.blob),
                    fileName: file.fileName,
                  }))}
                  config={{
                    header: {
                      disableHeader: false,
                      disableFileName: true,
                      retainURLParams: true,
                    },
                  }}
                />
              )}
            </StyledDocViewerWrapper>
          </Box>
        </Box>
      </StyledModal>
    );
  },
);

export const PreviewModalId = 'PreviewModal';

NiceModal.register(PreviewModalId, PreviewModal);
