import { FC, memo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { PAGE_HEADER_RAW_HEIGHT } from '@constants/common';
import { useBreakpoints } from '@hooks/useBreakpoints';
import ActiveTab from '@pages/InvoiceDetails/components/ActiveTab';
import DetailsHeader from '@pages/InvoiceDetails/components/DetailsHeader';
import ToggleTabs from '@pages/InvoiceDetails/components/ToggleTabs';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetails/context';

import { Box, LinearProgress } from '@mui/material';

const InvoiceDetails: FC = () => {
  const {
    isLoadingInvoices,
    invoiceData,
    isLoadingCategoryOptions,
    handleResetForm,
    isLoadingReasons,
    invoiceForm,
    isLoadingChanges,
  } = useInvoiceDetailsNewContext();
  const { t } = useTranslation();
  const { downMd, upLg } = useBreakpoints();

  const isLoading = [isLoadingInvoices, isLoadingCategoryOptions, isLoadingReasons, isLoadingChanges].some(Boolean);

  useEffect(() => {
    if (invoiceData) {
      handleResetForm();
    }
    invoiceForm.trigger();
  }, [handleResetForm, invoiceData, invoiceForm]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.invoiceDetails')}</title>
      </Helmet>

      <DetailsHeader data={invoiceData} />

      <Box height={`calc(100% - ${PAGE_HEADER_RAW_HEIGHT}px)`} width="auto">
        <ToggleTabs />
        <Box
          px={upLg ? '80px' : downMd ? '20px' : '30px'}
          pt={downMd ? '21px' : '40px'}
          pb={downMd ? '38px' : '60px'}
          width="100%"
        >
          <ActiveTab />
        </Box>
      </Box>
    </>
  );
};

export default memo(InvoiceDetails);
