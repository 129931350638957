import { forwardRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledRegularTextField } from '@pages/styled';

import { InputAdornment, InputProps, TextFieldProps, Typography } from '@mui/material';

import { StyledIconButton } from './styled';

type InputPasswordProps = Omit<TextFieldProps, 'type' | 'InputProps'> & { input?: Omit<InputProps, 'endAdornment'> };

const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>(({ input, disabled, ...mui }, ref) => {
  const [isShownPassword, setShownPassword] = useState(false);
  const { t } = useTranslation();
  const togglePassword = useCallback(() => {
    if (!disabled) {
      setShownPassword(prevState => !prevState);
    }
  }, [disabled]);

  return (
    <StyledRegularTextField
      ref={ref}
      type={isShownPassword ? 'text' : 'password'}
      disabled={disabled}
      InputProps={{
        ...input,
        endAdornment: (
          <InputAdornment position="end">
            <StyledIconButton onClick={togglePassword} disableRipple disableFocusRipple disableTouchRipple>
              <Typography fontSize={13} lineHeight="22px" letterSpacing="0.2px" mr={1}>
                {isShownPassword ? t('common.hide') : t('common.show')}
              </Typography>
            </StyledIconButton>
          </InputAdornment>
        ),
      }}
      {...mui}
    />
  );
});

export default InputPassword;
