import { CategoryRead, InvoiceChangeLogRead, InvoiceRead, ReasonRead } from '@api/api';
import { AUTOFILL_FIELDS_LIST, CATEGORY_FIELD } from '@pages/InvoiceDetails/constants';
import { IconStatus } from '@pages/InvoiceDetails/enums';

import { Option } from '@/globalTypes';

export const requiredFieldsMap = [
  { name: 'description', hasAlternative: false },
  { name: 'total_amount', hasAlternative: false },
  { name: 'vendor_name', hasAlternative: false },
  { name: 'service_end_date', hasAlternative: true, alternative: ['service_exact_date'] },
  { name: 'service_start_date', hasAlternative: true, alternative: ['service_exact_date'] },
  { name: 'service_exact_date', hasAlternative: true, alternative: ['service_start_date', 'service_end_date'] },
];

export const getReasonsOptions = (reasonRead?: ReasonRead[]) =>
  reasonRead?.map(reason => ({ id: reason.id, label: reason.name, value: reason.name })) || [];

export function findCategoryOption(
  categoryData: { id: number; name: string },
  options: CategoryRead[],
): Option | undefined {
  const matchingCategory = options.find(option => option.id === categoryData.id);
  return matchingCategory
    ? { id: matchingCategory.id, label: matchingCategory.name, value: matchingCategory.name }
    : undefined;
}

export function getCategoriesOptions(categories?: CategoryRead[]): Option[] {
  return (
    categories?.map(category => ({
      id: category.id as number,
      label: category.name,
      value: category.name as string,
    })) || []
  );
}

export function findReasonOption(reasonData: { id: number; name: string }, options: ReasonRead[]): Option | undefined {
  const match = options.find(option => option.id === reasonData.id);
  return match ? { id: match.id, label: match.name, value: match.name } : undefined;
}

export const getMissingFields = (data?: InvoiceRead): Array<string> | [] => {
  if (!data) return [];
  const result = new Set<string>();

  requiredFieldsMap.forEach(({ name, hasAlternative, alternative }) => {
    const value = data[name as keyof InvoiceRead];

    if (!value) {
      if (!hasAlternative) {
        result.add(name);
      } else if (hasAlternative && alternative) {
        const hasAlternativeValue = alternative.some(altName => data[altName as keyof InvoiceRead]);
        if (!hasAlternativeValue) {
          result.add(name);
        }
      }
    }
  });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { service_exact_date, service_start_date, service_end_date } = data;

  if (!service_exact_date && (!service_start_date || !service_end_date)) {
    if (!service_start_date) result.add('service_start_date');
    if (!service_end_date) result.add('service_end_date');
  }

  return Array.from(result);
};

export const getAutofillNumber = (changes?: InvoiceChangeLogRead[], data?: InvoiceRead): number => {
  const matchedFields = AUTOFILL_FIELDS_LIST.filter(field => {
    const isChanged = changes?.some(
      change => change.field_name === field || (field === CATEGORY_FIELD && data?.[field as keyof InvoiceRead]),
    );

    const isMissing = data?.[field as keyof InvoiceRead] === null || data?.[field as keyof InvoiceRead] === undefined;
    return isChanged || isMissing;
  }).length;

  return AUTOFILL_FIELDS_LIST.length - matchedFields;
};

export const getDetailsIconEnum = (
  field: keyof InvoiceRead | 'participant',
  value?: string | null,
  changes?: InvoiceChangeLogRead[],
) => {
  if (field === 'original_filename' && value) {
    return IconStatus.DOCUMENT;
  }

  if (field === 'original_filename' && !value) {
    return IconStatus.MISSING_DOCUMENT;
  }

  if (!value) {
    if (field === 'abn') {
      const hasReason = changes?.some(change => change.field_name === 'reason');
      return hasReason ? IconStatus.NONE : IconStatus.INFO;
    }

    return IconStatus.ACTION;
  }

  // ToDo temporary ignore until api updates
  // @ts-ignore
  if (value && field !== 'category' && field !== 'reason' && field !== 'participant') {
    const fieldChanged = changes?.some(change => change.field_name === field);

    if (!fieldChanged) {
      return IconStatus.PREFILLED;
    }
  }

  return IconStatus.NONE;
};

export const formatABN = (value: string) => {
  const digits = value.replace(/\D/g, '');

  /** capture digits in groups and place spaces between them like so XX XXX XXX XXX */
  return digits.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4');
};

export const truncateText = (maxLength: number, text?: string | null) => {
  if (!text) return '';
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};
