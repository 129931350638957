import { useCallback, useState } from 'react';
import * as yup from 'yup';

const passwordSchema = yup.string().minMax(8, 25).containsDigit().containsUppercase();

export const usePasswordValidation = () => {
  const [errors, setErrors] = useState([]);

  const validatePassword = useCallback((password: string) => {
    passwordSchema
      .validate(password, { abortEarly: false })
      .then(() => {
        setErrors([]);
      })
      .catch(error => {
        setErrors(error.inner.map((e: yup.ValidationError) => e.type));
      });
  }, []);

  return {
    validatePassword,
    passwordErrors: errors,
  };
};
