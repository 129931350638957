import { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useApiInvoicesNotesCreateMutation,
  useApiInvoicesNotesDestroyMutation,
  useApiInvoicesNotesListQuery,
  useApiInvoicesNotesPartialUpdateMutation,
} from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import NiceModal from '@ebay/nice-modal-react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import EmptyNotes from '@pages/InvoiceDetails/components/NotesTab/components/EmptyNotes';
import ModifyNote from '@pages/InvoiceDetails/components/NotesTab/components/ModifyNote';
import Note from '@pages/InvoiceDetails/components/NotesTab/components/Note';
import { ADD, EDIT, EMPTY } from '@pages/InvoiceDetails/components/NotesTab/constants';
import { DeleteNoteModalId } from '@pages/InvoiceDetails/components/NotesTab/modals/DeleteNoteModal';
import { StyledAddNoteButton } from '@pages/InvoiceDetails/components/NotesTab/styled';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetails/context';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import { Box, Typography } from '@mui/material';

const NotesTab: FC = () => {
  const { t } = useTranslation();
  const [addNote, setAddNote] = useState(false);
  const [editableNoteId, setEditableNoteId] = useState<number | null>(null);
  const { invoiceId } = useInvoiceDetailsNewContext();
  const { downMd } = useBreakpoints();
  const snackbar = useSnackbar();

  const { data: notesList, isLoading: isLoadingNotes, refetch: refetchList } = useApiInvoicesNotesListQuery({ invoiceId: +invoiceId });
  const [createNoteMutation, { isLoading: isLoadingCreateMutation }] = useApiInvoicesNotesCreateMutation();
  const [editNoteMutation, { isLoading: isLoadingEditMutation }] = useApiInvoicesNotesPartialUpdateMutation();
  const [deleteMutation, { isLoading: isLoadingDeleteMutation }] = useApiInvoicesNotesDestroyMutation();

  const handleAddNote = () => setAddNote(true);
  const handleCancelNote = () => setAddNote(false);
  const handleEdit = (noteId: number) => setEditableNoteId(noteId);
  const handleCancelEdit = () => setEditableNoteId(null);

  const getNoteText = useCallback(() => {
    if (notesList && notesList.length > 0) {
      return notesList.find(n => n.id === editableNoteId)?.text;
    }
    return '';
  }, [editableNoteId, notesList]);

  const handleDeleteNote = useCallback(
    async (noteId: number) => {
      try {
        const result = await NiceModal.show(DeleteNoteModalId, {
          title: t('dashboard.notes.deleteModalTitle'),
          buttonText: t('common.delete'),
          cancelButtonText: t('common.cancel'),
        });

        if (result) {
          await deleteMutation({ id: noteId, invoiceId: +invoiceId });
        }
      } catch (err) {
        snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      }
    },
    [deleteMutation, invoiceId, snackbar, t],
  );

  const handleCreateNote = useCallback(
    async (text: string) => {
      try {
        await createNoteMutation({
          invoiceId: +invoiceId,
          invoiceNoteRequest: {
            text,
          },
        });
        refetchList();
      } catch (err) {
        snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      } finally {
        setAddNote(false);
      }
    },
    [createNoteMutation, invoiceId, snackbar],
  );

  const handleUpdateNote = useCallback(
    async (updatedText: string) => {
      try {
        if (editableNoteId) {
          await editNoteMutation({
            id: editableNoteId,
            invoiceId: +invoiceId,
            patchedInvoiceNoteRequest: {
              text: updatedText,
            },
          });
        }
      } catch (err) {
        snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      } finally {
        setEditableNoteId(null);
      }
    },
    [editNoteMutation, editableNoteId, invoiceId, snackbar],
  );

  const statusKey = editableNoteId ? EDIT : addNote ? ADD : EMPTY;

  if (isLoadingNotes) return null;

  return (
    <Box width="100%" height="100%">
      <Box
        mb={!addNote && !editableNoteId ? 4 : 0}
        display="flex"
        flexDirection={downMd ? 'row' : 'column'}
        justifyContent="space-between"
        gap={3}
      >
        <Typography fontFamily="WFVisualSans" fontSize={20}>
          {t(
            {
              empty: 'dashboard.notes.title',
              edit: 'dashboard.notes.editNote',
              add: 'dashboard.notes.addNoteBtnText',
            }[statusKey],
          )}
        </Typography>
        {!addNote && !editableNoteId && notesList && notesList.length > 0 && (
          <StyledAddNoteButton
            endIcon={<AddIcon />}
            variant="text"
            color="primary"
            onClick={handleAddNote}
            sx={{ paddingLeft: 0, paddingRight: 0, width: 100, justifyContent: 'start' }}
          >
            <Typography>{t('dashboard.notes.addNoteBtnText')}</Typography>
          </StyledAddNoteButton>
        )}
      </Box>
      {/* EMPTY STATE */}
      {!addNote && notesList && notesList.length < 1 && <EmptyNotes handleAdd={handleAddNote} />}
      {/* LIST OF NOTES */}
      {!addNote && !editableNoteId && notesList && (
        <Box display="flex" flexDirection="column" gap={3}>
          {notesList.map(({ id, text, created_at }) => (
            <Note
              key={id}
              text={text}
              created_at={created_at}
              id={id}
              activateEdit={handleEdit}
              handleDelete={handleDeleteNote}
              loadingDelete={isLoadingDeleteMutation}
            />
          ))}
        </Box>
      )}
      {/* ADD NOTE STATE */}
      {addNote && !editableNoteId && (
        <ModifyNote loading={isLoadingCreateMutation} handleCancel={handleCancelNote} handleSave={handleCreateNote} />
      )}
      {/* EDIT NOTE STATE */}
      {editableNoteId && !addNote && (
        <ModifyNote
          loading={isLoadingEditMutation}
          handleCancel={handleCancelEdit}
          handleSave={handleUpdateNote}
          noteText={getNoteText()}
        />
      )}
    </Box>
  );
};

export default memo(NotesTab);
