import { Link, LinkProps } from 'react-router-dom';

import {
  CircularProgress,
  CircularProgressProps,
  Link as MuiLink,
  LinkProps as MuiLinkProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledTextField = styled(TextField)<TextFieldProps>({
  '& .MuiInputLabel-root': {
    fontWeight: 400,
  },
  '& .MuiInputBase-root': {
    fontWeight: 400,
  },
});

export const StyledLink = styled(Link)<LinkProps & { component?: typeof Link }>(({ theme }) => ({
  color: theme.colors.primary.main,
  fontWeight: 500,
  fontSize: '13px',
  textDecoration: 'none',
}));

export const StyledTypography = styled(Typography)<TypographyProps & { theme?: Theme }>(({ theme }) => ({
  color: theme.colors.secondary.contrast,
}));

export const StyledCircularProgress = styled(CircularProgress)<CircularProgressProps & { theme?: Theme }>(
  ({ theme }) => ({
    color: theme.colors.primary.main,
  }),
);

export const StyledOuterLink = styled(MuiLink)<MuiLinkProps>(() => ({
  textDecoration: 'underline',
  textUnderlineOffset: '3px',
  color: 'black',
}));
