import { useAuthUserRetrieveQuery } from '@api/api';

export const useUser = () => {
  const { data, isLoading, error, refetch } = useAuthUserRetrieveQuery(undefined, { refetchOnFocus: true });

  const userName = data?.first_name || '';
  const userLastName = data?.last_name || '';
  const userFullName = `${userName} ${userLastName}`;
  const invoiceEmail = data?.invoice_email || '';

  return { data, isLoading, error, refetch, userFullName, invoiceEmail };
};
