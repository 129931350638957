import { Link, LinkProps, Paper, PaperProps, Typography, TypographyProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledPolicyTypography = styled(Typography)<TypographyProps>({
  fontSize: '12px',
  fontWeight: 400,
  maxWidth: 400,
});

export const StyledLink = styled(Link)<LinkProps>(() => ({
  textDecoration: 'underline',
  textUnderlineOffset: '3px',
  color: 'black',
}));

export const StyledPaper = styled(Paper)<PaperProps & { theme?: Theme }>(() => ({
  borderRadius: '10px',
}));
