import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from '@hooks/useBreakpoints';
import InvoiceEvent from '@pages/InvoiceDetails/components/EventsTab/components/InvoiceEvent';
import InvoiceEventTimeline from '@pages/InvoiceDetails/components/EventsTab/components/InvoiceEventTimeline';
import { ICON_TYPE } from '@pages/InvoiceDetails/components/EventsTab/constants';
import { StyledAlert } from '@pages/InvoiceDetails/components/EventsTab/styled';
import { EventDetails, EventIconType } from '@pages/InvoiceDetails/components/EventsTab/types';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetails/context';

import { timelineItemClasses } from '@mui/lab';
import Timeline from '@mui/lab/Timeline';
import { Box, Typography } from '@mui/material';

const staticEvents: EventDetails[] = [
  {
    iconType: ICON_TYPE.FUNDS as EventIconType,
    title: 'dashboard.events.fundsReceived',
    onAdd: async () => {},
  },
  {
    iconType: ICON_TYPE.CUSTOM as EventIconType,
    title: 'dashboard.events.customEvent',
    onAdd: async () => {},
  },
];

const staticTimeline = [
  {
    iconType: ICON_TYPE.EMAIL as EventIconType,
    eventDate: '2024-06-30',
    title: 'dashboard.events.emailedToPlatform',
    secondFieldTitle: 'dashboard.events.sender',
    secondFieldDescription: 'admin@accessaustralia.com.au',
  },
  {
    iconType: ICON_TYPE.SUBMITTED as EventIconType,
    eventDate: '2024-07-02',
    title: 'dashboard.events.claimSubmitted',
    secondFieldTitle: 'dashboard.events.claimIdNumber',
    secondFieldDescription: '4728593758',
    isSubmitted: true,
  },
  {
    iconType: ICON_TYPE.DUE as EventIconType,
    eventDate: '2024-07-08',
    chipTitle: 'Payment due',
    isAutogenerated: true,
    isEditable: true,
  },
  {
    iconType: ICON_TYPE.PROVIDER_PAID as EventIconType,
    eventDate: '2024-07-10',
    title: 'dashboard.events.providerPaid',
    secondFieldTitle: 'dashboard.events.transactionReference',
    isEditable: true,
  },
];

const EventsTab: FC = () => {
  const { t } = useTranslation();
  const { downMd } = useBreakpoints();
  const { showEventsAlert, setShowEventsAlert } = useInvoiceDetailsNewContext();

  return (
    <Box width="100%" height="100%">
      <Typography fontFamily="WFVisualSans" fontSize={20} mb={2}>
        {t('dashboard.events.title')}
      </Typography>
      {showEventsAlert && (
        <StyledAlert severity="info" variant="outlined" onClose={() => setShowEventsAlert(false)}>
          <Typography fontSize={14} fontWeight={400}>
            {t('dashboard.events.alertText')}
          </Typography>
        </StyledAlert>
      )}
      <Box
        display="flex"
        flexDirection={downMd ? 'column' : 'row'}
        pb={{
          xs: '120px',
          sm: '90px',
          md: '105px',
        }}
        gap={downMd ? '40px' : '30px'}
      >
        {/* TIMELINE COLUMN */}
        <Box flexGrow={1} flexBasis="50%">
          <Box mb={2} display="flex" alignItems="center" height={50}>
            <Typography fontFamily="WFVisualSans" fontSize={18}>
              {t('dashboard.events.timelineTitle')}
            </Typography>
          </Box>
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
              padding: 0,
            }}
          >
            {staticTimeline.map((timelineEvent, idx) => (
              <InvoiceEventTimeline
                key={timelineEvent.iconType}
                {...timelineEvent}
                isLast={idx === staticTimeline.length - 1}
              />
            ))}
          </Timeline>
        </Box>
        {/* ADD EVENT COLUMN */}
        <Box flexGrow={1} flexBasis="50%">
          <Box mb={2} display="flex" alignItems="center" height={50}>
            <Typography fontFamily="WFVisualSans" fontSize={18}>
              {t('dashboard.events.addEventTitle')}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column" gap="26px">
            {/* ToDo temporary until api introduced */}
            {staticEvents.map(ev => (
              <InvoiceEvent key={ev.iconType} {...ev} />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(EventsTab);
