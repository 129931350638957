import { FC, memo, useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEmailsInvoiceCheckUniqueCreateMutation } from '@api/api';
import { EMAIL_DOMAIN } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { StyledBoxWrapper, StyledCancelBtn, StyledCreateIdTextField } from '@pages/CreateID/components/styled';
import { useCreateIdContext } from '@pages/CreateID/context';
import { StyledCircularProgress } from '@pages/Login/components/styled';
import { getErrorMessage } from '@utils/getMessage';
import debounce from 'lodash-es/debounce';
import { useSnackbar } from 'notistack';

import { Box, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { ErrorIcon, SuccessIcon } from '@components/icons';
import LoadingButton from '@components/LoadingButton';

const debouncedCheckAvailability = debounce(checkAvailability => {
  checkAvailability();
}, 300);

const EditId: FC = () => {
  const [isAvailable, setIsAvailable] = useState<boolean | null>(null);
  const { t } = useTranslation();
  const { control, watch, getFieldState, setError, clearErrors, resetField } = useFormContext();
  const { toggleEdit, emailPrefix } = useCreateIdContext();
  const snackbar = useSnackbar();
  const { downSm } = useBreakpoints();

  const idField = watch('emailId');
  const isDisabled = !idField || idField?.length < 1;
  const isError = getFieldState('emailId').error;
  const emailToCheck = `${idField}${EMAIL_DOMAIN}`;

  const [focused, setFocused] = useState(!!emailPrefix.length);

  const [checkEmailMutation, { isLoading: isLoadingCheck }] = useEmailsInvoiceCheckUniqueCreateMutation();

  const handleCheckEmail = useCallback(async () => {
    if (isDisabled) {
      setIsAvailable(null);
      return;
    }

    try {
      const res = await checkEmailMutation({ inputRequest: { email: emailToCheck } });
      if (!res?.data?.is_available) {
        setError('emailId', {
          type: 'validate',
          message: t('common.notAvailable'),
        });
        setIsAvailable(false);
      } else {
        clearErrors('emailId');
        setIsAvailable(true);
      }
    } catch (error) {
      snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH));
    }
  }, [checkEmailMutation, emailToCheck, isDisabled, setError, clearErrors, snackbar, t]);

  const handleCancel = () => {
    resetField('emailId', { defaultValue: emailPrefix });
    toggleEdit();
  };

  useEffect(() => {
    if (idField && !isDisabled) {
      debouncedCheckAvailability(handleCheckEmail);
    } else {
      setIsAvailable(null);
    }
  }, [idField, isDisabled, handleCheckEmail]);

  return (
    <Box>
      <StyledBoxWrapper display="flex" flexDirection="column">
        <Controller
          name="emailId"
          control={control}
          render={({ field, fieldState }) => (
            <StyledCreateIdTextField
              label={focused ? t('auth.customId') : t('auth.enterCustomId')}
              variant="outlined"
              type="text"
              autoComplete="off"
              inputProps={{
                'aria-required': 'true',
                'aria-label': 'custom id input field',
              }}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error &&
                t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
              }
              slotProps={{
                input: {
                  endAdornment: !downSm && (
                    <Typography fontWeight={400} color={alpha('#000', 0.6)}>
                      {EMAIL_DOMAIN}
                    </Typography>
                  ),
                },
                htmlInput: {
                  maxLength: 30,
                },
              }}
              {...field}
              onFocus={() => setFocused(true)}
              onBlur={() => {
                if (!field.value) {
                  setFocused(false);
                }
              }}
            />
          )}
        />
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={1} height={24}>
          <Box flexGrow={1}>
            {isLoadingCheck && !isDisabled && (
              <Box>
                <StyledCircularProgress size={16} />
              </Box>
            )}
            {isAvailable && !isLoadingCheck && !isDisabled && (
              <Box alignItems="center" color="#1B5E20" display="flex" gap={1}>
                <Box mt={0.15}>
                  <SuccessIcon />
                </Box>
                <Typography fontSize={14} fontWeight={400}>
                  {t('common.available')}
                </Typography>
              </Box>
            )}
            {isError && !isLoadingCheck && (
              <Box alignItems="center" color="#D32F2F" display="flex" gap={1}>
                <Box mt={0.15}>
                  <ErrorIcon />
                </Box>
                <Typography fontSize={14} fontWeight={400}>
                  {t('common.notAvailable')}
                </Typography>
              </Box>
            )}
          </Box>
          {downSm && (
            <Box pb={0.75}>
              <Typography fontWeight={400} color={alpha('#000', 0.6)}>
                {EMAIL_DOMAIN}
              </Typography>
            </Box>
          )}
        </Box>
      </StyledBoxWrapper>

      <Box display="flex" justifyContent="space-between" mt={downSm ? 4 : 7} height={42}>
        <StyledCancelBtn variant="text" onClick={handleCancel}>
          {t('common.cancel')}
        </StyledCancelBtn>
        <LoadingButton
          variant="contained"
          loading={false}
          disabled={!isAvailable}
          type="submit"
          sx={{ minWidth: '147px', fontWeight: 500 }}
        >
          {t('common.save')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default memo(EditId);
