import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { useUser } from '@hooks/api/useUser';

import { Box } from '@mui/material';

const ConfirmEmailGuard: FC<PropsWithChildren> = ({ children }) => {
  const { data: user, isLoading } = useUser();
  const hasRights = user;

  if (isLoading) return null;
  if (user) return <Navigate to={ROUTING.ROOT} replace />;

  return <Box style={hasRights ? { visibility: 'hidden' } : undefined}>{!hasRights ? children : null}</Box>;
};

export default ConfirmEmailGuard;
