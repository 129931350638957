import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMenuActions } from '@hooks/useMenuActions';
import { EventIconType } from '@pages/InvoiceDetails/components/EventsTab/types';
import { ICONS_SVG_MAP } from '@pages/InvoiceDetails/components/EventsTab/utils';
import {
  StyledActionsButton,
  StyledActionsMenu,
  StyledActionsMenuItem,
} from '@pages/InvoiceDetails/components/NotesTab/styled';
import { convertIsoToReadable } from '@utils/formatTime';

import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import AutoFixHighTwoToneIcon from '@mui/icons-material/AutoFixHighTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { Box, Chip, Divider, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { StyledTypographyContrast } from '@/shared/styles';

const InvoiceEventTimeline: FC<{
  iconType: EventIconType;
  eventDate: string;
  isLast: boolean;
  title?: string; // if payment due then show Chip
  chipTitle?: string;
  secondFieldTitle?: string;
  secondFieldDescription?: string;
  isSubmitted?: boolean;
  isAutogenerated?: boolean;
  isEditable?: boolean;
}> = ({
  iconType,
  eventDate,
  title,
  secondFieldTitle,
  secondFieldDescription,
  chipTitle,
  isLast,
  isSubmitted,
  isAutogenerated,
  isEditable,
}) => {
  const { t } = useTranslation();
  const IconComponent = ICONS_SVG_MAP[iconType];
  const { anchorEl, open, handleClose, handleOpen } = useMenuActions();

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot sx={{ backgroundColor: '#F4ECFF' }}>
          <IconComponent color="primary" />
        </TimelineDot>
        <TimelineConnector sx={{ backgroundColor: '#F4ECFF', width: '5px', borderRadius: '5px' }} />
      </TimelineSeparator>
      <TimelineContent>
        <Box minHeight={isLast ? 100 : 140} pt="6px" pb={isLast ? 0 : 2.5}>
          <Box mb="12px">
            <Typography color={alpha('#000', 0.6)} fontWeight={400} pb="6px">
              {eventDate
                ? convertIsoToReadable(eventDate, {
                    fullYear: true,
                    dayFormat: 'd',
                  })
                : t('dashboard.events.noDateRecorded')}
            </Typography>
            {title && (
              <StyledTypographyContrast fontSize={18} fontFamily="WFVisualSans">
                {t(title)}
              </StyledTypographyContrast>
            )}
            {!title && chipTitle && (
              <Box my="11.5px">
                <Chip
                  label={t(chipTitle)}
                  color="secondary"
                  size="medium"
                  variant="filled"
                  sx={{ '& .MuiChip-label': { color: '#25175F', fontSize: '13px', fontWeight: 400 } }}
                />
              </Box>
            )}
          </Box>
          {secondFieldTitle && (
            <Box>
              <Typography color={alpha('#000', 0.6)} fontWeight={400} fontSize={14} pb="1px">
                {t(secondFieldTitle)}
              </Typography>
              <Typography fontWeight={400} fontSize={14}>
                {secondFieldDescription || t('common.none')}
              </Typography>
            </Box>
          )}
          {isSubmitted && (
            <Box display="flex" gap="4px" mt={1.5}>
              <Typography fontWeight={400} fontSize={14}>
                {t('dashboard.events.submittedWithWizard')}
              </Typography>
              <AutoFixHighTwoToneIcon fontSize="small" color="primary" />
            </Box>
          )}
          {isAutogenerated && (
            <Box display="flex" gap="4px" my={1.5}>
              <Typography fontWeight={400} fontSize={14}>
                {t('dashboard.events.generatedFromInvoice')}
              </Typography>
              <AutoAwesomeTwoToneIcon fontSize="small" color="primary" />
            </Box>
          )}
          {isEditable && (
            <>
              <StyledActionsButton variant="text" endIcon={<MoreHorizIcon color="primary" />} onClick={handleOpen}>
                <Typography fontSize={14}>{t('dashboard.notes.actionsTitle')}</Typography>
              </StyledActionsButton>

              <StyledActionsMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'actions-button' }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <StyledActionsMenuItem
                  onClick={() => {
                    handleClose();
                    // activateEdit(id);
                  }}
                >
                  <EditIcon color="action" />
                  <Typography fontWeight={400}>{t('common.edit')}</Typography>
                </StyledActionsMenuItem>
                <Divider />
                <StyledActionsMenuItem
                  onClick={() => {
                    handleClose();
                    // handleDelete(id);
                  }}
                  // disabled={loadingDelete}
                >
                  <DeleteIcon color="action" />
                  <Typography fontWeight={400}>{t('common.delete')}</Typography>
                </StyledActionsMenuItem>
              </StyledActionsMenu>
            </>
          )}
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

export default memo(InvoiceEventTimeline);
