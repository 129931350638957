import { Dispatch, FC, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledHint } from '@pages/ConfirmEmail/components/styled';
import { formatCountdownTime } from '@utils/formatTime';

const Countdown: FC<{
  countdown: number;
  setCountdown: Dispatch<SetStateAction<number>>;
  setCodeSent: Dispatch<SetStateAction<boolean>>;
}> = ({ countdown, setCountdown, setCodeSent }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevTime => {
        if (prevTime === 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [setCodeSent, setCountdown]);

  return (
    <StyledHint aria-label="countdown timer" aria-disabled>
      {formatCountdownTime(countdown, t('common.availableIn'))}
    </StyledHint>
  );
};

export default memo(Countdown);
