import { useTranslation } from 'react-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { StyledTextarea } from '@providers/layouts/AppLayout/styled';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@components/LoadingButton';

import { StyledModal } from '@/shared/styles';

const FeedbackModal = NiceModal.create(() => {
  const { t } = useTranslation();
  const modal = useModal();

  const handleConfirmClick = () => {
    // ToDo add api when it's going to be implemented
    modal.resolve(true);
    modal.remove();
  };
  const handleCancelClick = async () => {
    modal.resolve(false);
    modal.remove();
  };

  return (
    <StyledModal open={modal.visible} size="medium" onClose={handleCancelClick}>
      <Box position="absolute" top={15} right={15}>
        <IconButton disableRipple onClick={handleCancelClick}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box padding="24px 40px" display="flex" flexDirection="column" gap={2}>
        <Typography fontWeight={500} variant="h5" mb="12px" textAlign="center" maxWidth={400} alignSelf="center">
          {t('dashboard.navigation.modal.feedbackTitle')}
        </Typography>
        <Typography fontWeight={400} mb="12px" color="textSecondary">
          {t('dashboard.navigation.modal.feedbackSubtitle')}
        </Typography>
        <Box display="flex" alignItems="center" gap="12px">
          <StyledTextarea maxRows={10} minRows={4} placeholder={t('dashboard.navigation.modal.feedbackPlaceholder')} />
        </Box>
        <Box alignSelf="end">
          <LoadingButton loading={false} color="primary" onClick={handleConfirmClick}>
            {t('common.send')}
          </LoadingButton>
        </Box>
      </Box>
    </StyledModal>
  );
});

export const FeedbackModalId = 'FeedbackModal';

NiceModal.register(FeedbackModalId, FeedbackModal);
