import NiceModal, { useModal } from '@ebay/nice-modal-react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { StyledModal } from '../styles';

const NotificationModal = NiceModal.create(({ title }: { title: string | JSX.Element }) => {
  const modal = useModal();

  const handleCancelClick = async () => {
    modal.resolve(false);
    modal.remove();
  };

  return (
    <StyledModal
      open={modal.visible}
      size="small"
      onClose={handleCancelClick}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '320px',
        },
        '& .MuiDialog-paper': {
          boxShadow:
            '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important',
        },
        '& .MuiBackdrop-root': { backgroundColor: 'transparent' },
      }}
    >
      <Box position="absolute" top={10} right={10}>
        <IconButton onClick={handleCancelClick}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography
          maxWidth={300}
          variant="h5"
          mt="57px"
          mx={2.5}
          mb="40px"
          fontSize={20}
          textAlign="center"
          fontFamily="WFVisualSans"
          color="rgba(37, 23, 95, 1)"
        >
          {title}
        </Typography>
      </Box>
    </StyledModal>
  );
});

export const NotificationModalId = 'NotificationModal';

NiceModal.register(NotificationModalId, NotificationModal);
