import { FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '@constants/auth';
import { ROUTING } from '@constants/routing';
import { LoginSchema } from '@pages/Login/types';
import { StyledBox, StyledLoadingButton } from '@pages/styled';

import { Box, Checkbox, FormControlLabel, FormControlLabelProps, Typography } from '@mui/material';
import InputPassword from '@components/InputPassword/InputPassword';

import { StyledLink, StyledOuterLink, StyledTextField, StyledTypography } from './styled';

const componentsProps: FormControlLabelProps['componentsProps'] = {
  typography: {
    variant: 'body2',
    sx: theme => ({
      display: 'flex',
      columnGap: '4px',
      color: theme.colors.text,
      textIndent: 4,
    }),
  },
};

const LoginForm: FC<{ loading: boolean }> = ({ loading }) => {
  const { t } = useTranslation();
  const { control } = useFormContext<LoginSchema>();

  return (
    <>
      <StyledTypography fontSize={24} mb="28px" fontFamily="WFVisualSans">
        {t('auth.loginText')}
      </StyledTypography>
      <Box minWidth='320px'>
        <StyledBox>
          <Box mb={2} display="flex" flexDirection="column" gap={2}>
            <Controller
              control={control}
              render={({ field, fieldState }) => (
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  label={t('common.inputs.email')}
                  inputProps={{
                    'aria-required': 'true',
                    'aria-label': 'email input field',
                  }}
                  type="email"
                  autoComplete="off"
                  error={Boolean(fieldState.error)}
                  helperText={
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                  }
                  {...field}
                  onChange={e => {
                    field.onChange(e);
                  }}
                />
              )}
              name="email"
            />
            <Controller
              control={control}
              render={({ field, fieldState }) => (
                <InputPassword
                  fullWidth
                  variant="outlined"
                  label={t('common.inputs.password')}
                  inputProps={{
                    'aria-required': 'true',
                    'aria-label': 'password input field',
                  }}
                  autoComplete="off"
                  error={Boolean(fieldState.error)}
                  helperText={
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                  }
                  {...field}
                  onChange={e => {
                    field.onChange(e);
                  }}
                />
              )}
              name="password"
            />
            <FormControlLabel
              componentsProps={componentsProps}
              sx={{ marginLeft: 0 }}
              control={
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox checked={!!value} onChange={e => onChange(e.target.checked)} />
                  )}
                  name="rememberMe"
                />
              }
              label={t('auth.rememberMe')}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={2} zIndex={2000}>
            <StyledLoadingButton
              fullWidth
              variant="contained"
              color="primary"
              loading={loading}
              type="submit"
              aria-label="submit button"
              sx={{
                '&.MuiButtonBase-root': {
                  fontSize: 16,
                },
                boxShadow:
                  '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
              }}
            >
              {t('common.helmetTitles.login')}
            </StyledLoadingButton>

            <Typography fontSize={12} fontWeight={400} lineHeight="19.92px">
              <Trans
                i18nKey="auth.privacyPolicy"
                components={{
                  termsOfService: <StyledOuterLink href={TERMS_OF_SERVICE_URL} target="_blank" />,
                  privacyPolicy: <StyledOuterLink href={PRIVACY_POLICY_URL} target="_blank" />,
                }}
              />
            </Typography>

            <StyledLink
              component={RouterLink}
              to={`/${ROUTING.FORGOT_PASSWORD}`}
              aria-label="redirect to forgot password page"
            >
              {t('common.forgotPassword')}
            </StyledLink>

            <Box>
              <Typography fontSize={12} fontWeight={400} lineHeight="19.92px">
                {t('common.accountCaptionText')}
              </Typography>
              <StyledLink component={RouterLink} to={`/${ROUTING.SIGNUP}`} aria-label="redirect to signup page">
                {t('auth.createAccount')}
              </StyledLink>
            </Box>
          </Box>
        </StyledBox>
      </Box>
    </>
  );
};

export default memo(LoginForm);
