import { useSubscriptionsRetrieveQuery } from '@api/api';

export const useSubscription = () => {
  const { data, isLoading, error } = useSubscriptionsRetrieveQuery();

  const isTrial = data?.is_trial ?? false;
  const trialDaysLeft = data?.trial_days_left ?? 0;
  const daysLeft = data?.days_left ?? 0;
  const subscriptionStatus = data?.status ?? '';

  return {
    isTrial,
    trialDaysLeft,
    daysLeft,
    isLoadingSubscription: isLoading,
    subscriptionStatus,
    subscriptionError: error,
    data,
  };
};
