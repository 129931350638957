import { USER_ACCESS_TOKEN, USER_REFRESH_TOKEN } from '@constants/auth';

export const tokenStorage = {
  getToken(key: string, useSession: boolean = false) {
    const storage = useSession ? sessionStorage : localStorage;
    const token = storage.getItem(key);

    return token || null;
  },

  setToken(key: string, token: string, useSession: boolean = false) {
    const storage = useSession ? sessionStorage : localStorage;
    storage.setItem(key, token);
  },

  getAccessToken(useSession: boolean = false) {
    return this.getToken(USER_ACCESS_TOKEN, useSession);
  },

  getRefreshToken(useSession: boolean = false) {
    return this.getToken(USER_REFRESH_TOKEN, useSession);
  },

  setAccessToken(token: string, useSession: boolean = false) {
    this.setToken(USER_ACCESS_TOKEN, token, useSession);
  },

  setRefreshToken(token: string, useSession: boolean = false) {
    this.setToken(USER_REFRESH_TOKEN, token, useSession);
  },

  removeToken(useSession: boolean = false) {
    const storage = useSession ? sessionStorage : localStorage;
    storage.removeItem(USER_ACCESS_TOKEN);
  },

  cleanup() {
    sessionStorage.removeItem(USER_ACCESS_TOKEN);
    sessionStorage.removeItem(USER_REFRESH_TOKEN);
    localStorage.removeItem(USER_ACCESS_TOKEN);
    localStorage.removeItem(USER_REFRESH_TOKEN);
  },
};
