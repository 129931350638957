import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { RootState } from '@store/store';

const PrivateRoutes: FC = () => {
  const authState = useSelector((state: RootState) => state.auth);

  if (!authState.isAuthenticated && authState.isConfirmed !== false) return <Navigate to={ROUTING.LOGIN} replace />;

  return <Outlet />;
};

export default memo(PrivateRoutes);
