import { ParticipantData } from '@pages/Relationship/types';

export const INITIAL_PARTICIPANT: ParticipantData = {
  participantName: '',
};

export const PARTICIPANT_FORM_SHAPE: ParticipantData[] = [INITIAL_PARTICIPANT];

export const DEFAULT_FORM_VALUES = {
  data: PARTICIPANT_FORM_SHAPE,
  participant: false,
  nominee: false,
  childRepresentative: false,
}

export const PARTICIPANT_CHECKBOX = 'participant';
export const NOMINEE_CHECKBOX = 'nominee';
export const CHILD_REPRESENTATIVE_CHECKBOX = 'childRepresentative';

