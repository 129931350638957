import { FC, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BillingPeriod } from '@constants/enums';
import { AUD, YEARLY_SAVE_TOTAL } from '@pages/SubscribePage/constants';
import { CardContainer, StyledPrimaryBox } from '@pages/SubscribePage/styled';
import { CardProps } from '@pages/SubscribePage/types';

import DoneIcon from '@mui/icons-material/Done';
import { Box, Typography } from '@mui/material';

import { StyledTypographyContrast, StyledTypographyPrimaryDark } from '@/shared/styles';

const Card: FC<CardProps & { isSelected: boolean; onPeriodChange: (period: BillingPeriod) => void }> = ({
  config,
  isSelected,
  plan,
  onPeriodChange,
}) => {
  const { t } = useTranslation();
  const { text, title, cta, period, price } = config;

  return (
    <CardContainer
      elevation={isSelected ? 9 : 0}
      isSelected={isSelected}
      onClick={() => onPeriodChange(plan as BillingPeriod)}
      sx={{
        maxWidth: {
          xs: 'auto',
          md: '408px',
        },
      }}
    >
      <StyledPrimaryBox py={1.25} display="flex" pl={3.5} width="100%" isSelected={isSelected} height="41px">
        <Typography fontSize={14} fontWeight={500}>
          {t(cta)}
        </Typography>
      </StyledPrimaryBox>
      <Box display="flex" flexDirection="column" px="30px" py="25px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <StyledTypographyContrast fontSize={24} fontWeight={500} fontFamily="WFVisualSans">
            {t(title)}
          </StyledTypographyContrast>
          {isSelected && <DoneIcon color="primary" />}
        </Box>

        <StyledTypographyContrast fontSize={20} fontWeight={400} mt={3}>
          {`$${price.toFixed(2)} ${AUD}, ${t(period)}`}
        </StyledTypographyContrast>

        <StyledTypographyContrast fontSize={14} fontWeight={400} mt={2} maxWidth={360}>
          <Trans
            i18nKey={text}
            components={{
              bold: <StyledTypographyPrimaryDark fontWeight={600} variant="subtitle1" component="span" />,
            }}
            values={{
              total: YEARLY_SAVE_TOTAL,
            }}
          />
        </StyledTypographyContrast>
      </Box>
    </CardContainer>
  );
};

export default memo(Card);
