import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useApiInvoicesListQuery } from '@api/api';
import { ROUTING } from '@constants/routing';
import useDownloadFile from '@hooks/api/useDownloadFile';
import { usePageFilterUrlParams } from '@hooks/usePageFilterUrlParams';
import { useSubscription } from '@hooks/useSubscription';
import InvoicesTable from '@pages/Invoices/components/InvoicesTable';
import SearchField from '@pages/Invoices/components/SearchField';
import PreviewInvoice from '@pages/Invoices/modals/PreviewInvoice';
import { StyledControlsBox, StyledFiltersButton, StyledLoadingButton } from '@pages/Invoices/styled';

import FilterListIcon from '@mui/icons-material/FilterList';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Typography } from '@mui/material';
import { GridPaginationModel } from '@mui/x-data-grid';
import FloatingViewButton from '@components/FloatingViewButton';

const Invoices: FC = () => {
  const { page, limit, setPage, setLimit } = usePageFilterUrlParams();
  const { t } = useTranslation();
  const { exportExcelFiles, isLoadingExcel } = useDownloadFile();
  const navigate = useNavigate();
  const { isLoadingSubscription } = useSubscription();

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPage(newPaginationModel.page);
    setLimit(newPaginationModel.pageSize);
  };

  const {
    data: invoicesData,
    isLoading: isLoadingInvoices,
    isFetching,
  } = useApiInvoicesListQuery({
    limit,
    offset: page * limit,
  });
  const isLoading = [isLoadingInvoices, isFetching, isLoadingSubscription].some(Boolean);

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.invoicesReceipts')}</title>
      </Helmet>

      <Box aria-label="invoices page" display="flex" gap={2} flexDirection="column" height="calc(100vh - 150px)">
        <StyledControlsBox aria-label="title area" display="flex" justifyContent="space-between">
          <Box display="flex" gap={3}>
            <StyledFiltersButton
              disableRipple
              disableTouchRipple
              variant="text"
              startIcon={<FilterListIcon color="primary" />}
            >
              <Typography>{t('dashboard.filters')}</Typography>
            </StyledFiltersButton>
            <StyledLoadingButton
              disableRipple
              disableTouchRipple
              variant="text"
              loading={isLoadingExcel}
              startIcon={<SaveAltIcon color="primary" />}
              onClick={exportExcelFiles}
            >
              <Typography>{t('dashboard.exportCsv')}</Typography>
            </StyledLoadingButton>
          </Box>
          <SearchField />
        </StyledControlsBox>

        <Box position="relative">
          <InvoicesTable
            invoices={invoicesData}
            isLoading={isLoading}
            handlePageChange={handlePaginationModelChange}
            paginationModel={{ page, pageSize: limit }}
          />
          {!isLoading && (
            <Box position="absolute" right={16} bottom={32} zIndex={1000}>
              <FloatingViewButton
                locale="dashboard.navigation.uploadInvoice"
                icon={UploadFileIcon}
                onClick={() => navigate(`/${ROUTING.INVOICE_UPLOAD}`)}
                size="small"
              />
            </Box>
          )}
        </Box>
        <PreviewInvoice />
      </Box>
    </>
  );
};

export default memo(Invoices);
