import { FC, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BREAKPOINTS, UP } from '@constants/common';
import { ROUTING } from '@constants/routing';
import { useResponsive } from '@hooks/useResponsive';
import useWindowSize from '@hooks/useWindowSize';
import { StyledSubscribeButton, StyledTrialBox } from '@providers/layouts/AppLayout/styled';

import { Box, Typography } from '@mui/material';

const TrialBar: FC<{ daysLeft: number }> = ({ daysLeft }) => {
  const upMd = useResponsive(UP, BREAKPOINTS.MD);
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const showDays = width >= 376;
  const navigate = useNavigate();

  return (
    <StyledTrialBox px={upMd ? '80px' : '30px'} display="flex" alignItems="center" justifyContent="space-between">
      <Box>
        <Trans
          i18nKey="dashboard.trialDaysLeft"
          values={{ daysLeft }}
          components={{
            strong: <Typography component="span" fontWeight={700} fontSize={13} />,
            regular: (
              <Typography component="span" fontWeight={400} fontSize={13} display={showDays ? 'inline' : 'none'} />
            ),
          }}
        />
      </Box>
      <StyledSubscribeButton disableRipple disableTouchRipple disableFocusRipple variant="text" onClick={() => navigate(`/${ROUTING.SUBSCRIBE}`)}>
        <Typography fontWeight={500} fontSize={14}>
          {t('auth.subscribe')}
        </Typography>
      </StyledSubscribeButton>
    </StyledTrialBox>
  );
};

export default memo(TrialBar);
