import { FC, memo, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ParserUploadCreateApiArg } from '@api/api';
import { ERROR, USER_ACCESS_TOKEN } from '@constants/auth';
import { API_ERROR_MSG_PATH, MAX_UPLOAD_FILES } from '@constants/common';
import { UPLOAD_FILE_ERROR } from '@constants/errors';
import { ROUTING } from '@constants/routing';
import { StyledLink } from '@pages/styled';
import FileItem from '@pages/UploadInvoice/components/FileItem';
import { StyledAddFilesButton, StyledUploadBox } from '@pages/UploadInvoice/styled';
import { getErrorMessage } from '@utils/getMessage';
import { getToken } from '@utils/manipulateStorage';
import { useSnackbar } from 'notistack';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import UploadIcon from '@mui/icons-material/Upload';
import { Alert, Box, List, ListItem, Typography } from '@mui/material';
import LoadingButton from '@components/LoadingButton';

const baseUrl = import.meta.env.VITE_APP_API_URL;
const uploadFileMutation = async ({ body }: ParserUploadCreateApiArg) => {
  const formData = new FormData();
  if (body.file) {
    formData.append('file', body.file);
  }
  const token = getToken(USER_ACCESS_TOKEN);

  const response = await fetch(`${baseUrl}/parser/upload/`, {
    method: 'POST',
    body: formData,
    headers: { Authorization: `Bearer ${token}` },
  });

  if (!response.ok) {
    throw new Error('Failed to upload file');
  }

  return response.json();
};

const UploadInvoice: FC = () => {
  const { t } = useTranslation();
  const [addedFiles, setAddedFiles] = useState<File[]>([]);
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleChooseFile = () => {
    if (uploadingFiles) return;
    fileInputRef.current?.click();
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (addedFiles.length + acceptedFiles.length > MAX_UPLOAD_FILES) {
      const remainingSlots = MAX_UPLOAD_FILES - addedFiles.length;
      const filesToAdd = acceptedFiles.slice(0, remainingSlots);
      setAddedFiles([...addedFiles, ...filesToAdd]);
      snackbar.enqueueSnackbar(t('dashboard.dragAndDropLimitNotification', { maxFiles: MAX_UPLOAD_FILES }), {
        variant: 'warning',
      });
    } else {
      setAddedFiles([...addedFiles, ...acceptedFiles]);
    }
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
    maxFiles: MAX_UPLOAD_FILES,
  });

  const handleRemoveFile = (fileIndex: number) => {
    setAddedFiles(addedFiles.filter((_, index) => index !== fileIndex));
  };

  const handleUpload = async () => {
    try {
      setUploadingFiles(true);
      // eslint-disable-next-line no-restricted-syntax
      for (const file of addedFiles) {
        // eslint-disable-next-line no-await-in-loop
        await uploadFileMutation({ body: { file } });
      }
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
        navigate(`/${ROUTING.INVOICES}`);
      }, 5000);
    } catch (error) {
      snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH), { variant: ERROR });
    } finally {
      setAddedFiles([]);
      setUploadingFiles(false);
    }
  };

  useEffect(() => {
    if (fileRejections && fileRejections[0]?.errors?.[0].code === UPLOAD_FILE_ERROR.TOO_MANY_FILES) {
      snackbar.enqueueSnackbar(t('dashboard.dragAndDropLimitNotification', { maxFiles: MAX_UPLOAD_FILES }), {
        variant: 'warning',
      });
    }
    if (fileRejections && fileRejections[0]?.errors?.[0].code === UPLOAD_FILE_ERROR.INVALID_FILE_TYPE) {
      snackbar.enqueueSnackbar(fileRejections[0].errors[0].message, {
        variant: 'warning',
      });
    }
  }, [fileRejections, snackbar, t]);

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.uploadInvoice')}</title>
      </Helmet>

      <Box display="flex" flexDirection="column" p={5}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" height="50px">
          <StyledLink component={RouterLink} to={`/${ROUTING.INVOICES}`} replace aria-label="back button">
            <Box display="flex" alignItems="center" gap={0.5} ml="-4px">
              <ChevronLeftIcon />{' '}
              <Typography fontSize={16} fontWeight={500}>
                {t('common.back')}
              </Typography>
            </Box>
          </StyledLink>
          {showSuccessAlert && (
            <Box flex={1} display="flex" justifyContent="center">
              <Alert severity="success" sx={{ width: '685px', boxShadow: 'none', marginRight: '60px' }}>
                <Typography fontWeight={500} color="#1E4620">
                  {t('dashboard.uploadSuccess')}
                </Typography>
              </Alert>
            </Box>
          )}
        </Box>

        <Box
          width={543}
          mt={25}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignSelf="center"
          gap={1.5}
        >
          <Box
            {...getRootProps()}
            aria-label="Drag and drop zone"
            sx={{ pointerEvents: uploadingFiles ? 'none' : 'inherit' }}
          >
            <input {...getInputProps()} ref={fileInputRef} aria-label="File input" disabled={uploadingFiles} />
            <StyledUploadBox textAlign="center" display="flex" gap={3} flexDirection="column" alignItems="center">
              <UploadIcon color="primary" fontSize="large" />
              <Typography fontWeight={500} fontSize={20} fontFamily="WFVisualSans">
                {t('dashboard.dragAndDropTitle')}
              </Typography>
              <Typography fontWeight={400} fontSize={14}>
                ({t('dashboard.dragAndDropFileTypes', { maxFiles: MAX_UPLOAD_FILES })})
              </Typography>
            </StyledUploadBox>
          </Box>

          <List aria-label="List of added files">
            {addedFiles.map((file, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={index} sx={{ padding: '0px !important', margin: '10px 0' }}>
                <FileItem name={file.name} onRemove={() => handleRemoveFile(index)} />
              </ListItem>
            ))}
          </List>

          <Box display="flex" gap={2} justifyContent="center" height={42}>
            <StyledAddFilesButton
              sx={{ width: addedFiles.length > 0 ? 205 : 150 }}
              variant="outlined"
              color="primary"
              onClick={handleChooseFile}
              disabled={uploadingFiles}
            >
              {addedFiles.length > 0 ? t('dashboard.chooseAnotherFile') : t('dashboard.chooseAFile')}
            </StyledAddFilesButton>
            {addedFiles.length > 0 && (
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleUpload}
                loading={uploadingFiles}
                aria-label={t('dashboard.uploadFiles')}
                aria-disabled={addedFiles.length === 0}
                sx={{ padding: '8px 22px' }}
              >
                {t('dashboard.uploadFiles')}
              </LoadingButton>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default memo(UploadInvoice);
