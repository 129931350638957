import { useTranslation } from 'react-i18next';
import { BREAKPOINTS, DOWN } from '@constants/common';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useResponsive } from '@hooks/useResponsive';
import { StyledTextButton } from '@pages/InvoiceDetails/styled';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { StyledModal } from '../styled';

const UndoModal = NiceModal.create(
  ({
    title,
    text,
    buttonText,
    cancelButtonText,
  }: {
    title: string;
    text: string;
    buttonText: string;
    cancelButtonText?: string;
  }) => {
    const { t } = useTranslation();
    const modal = useModal();
    const downSm = useResponsive(DOWN, BREAKPOINTS.SM);

    const handleConfirmClick = () => {
      modal.resolve(true);
      modal.remove();
    };
    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    return (
      <StyledModal open={modal.visible} size="medium" onClose={handleCancelClick}>
        <Box padding="16px 24px">
          {!downSm && (
            <Box position="absolute" top={10} right={12}>
              <IconButton onClick={handleCancelClick}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          <Box display="flex">
            <Typography variant="h6" fontFamily="WFVisualSans" fontWeight={500}>
              {t(title)}
            </Typography>
          </Box>

          <Typography variant="body1" fontWeight={400} mt={2.5}>
            {text}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" px={2} pb={1} mt={1}>
          <StyledTextButton
            disableRipple
            disableElevation
            disableTouchRipple
            disableFocusRipple
            variant="text"
            color="primary"
            onClick={handleConfirmClick}
          >
            {buttonText}
          </StyledTextButton>
          <Button
            disableRipple
            disableElevation
            disableTouchRipple
            disableFocusRipple
            variant="contained"
            color="primary"
            onClick={handleCancelClick}
            sx={{ height: 36, width: 84, boxShadow: 2 }}
          >
            {cancelButtonText}
          </Button>
        </Box>
      </StyledModal>
    );
  },
);

export const UndoModalId = 'UndoModal';

NiceModal.register(UndoModalId, UndoModal);
