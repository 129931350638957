import styled from '@mui/system/styled';

const InvoiceClaimedIcon = styled(props => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity="0.3"
      d="M19.7063 6.33301H9.25V29.6663H26.75V13.3768L19.7063 6.33301ZM22.375 16.5413H16.5417V17.9997H20.9167C21.7188 17.9997 22.375 18.6559 22.375 19.458V23.833C22.375 24.6351 21.7188 25.2913 20.9167 25.2913H19.4583V26.7497H16.5417V25.2913H13.625V22.3747H19.4583V20.9163H15.0833C14.2812 20.9163 13.625 20.2601 13.625 19.458V15.083C13.625 14.2809 14.2812 13.6247 15.0833 13.6247H16.5417V12.1663H19.4583V13.6247H22.375V16.5413Z"
      fill="#8435E9"
    />
    <path
      d="M19.7062 6.33366L26.75 13.3774V29.667H9.24998V6.33366H19.7062ZM20.9166 3.41699H9.24998C7.64581 3.41699 6.33331 4.72949 6.33331 6.33366V29.667C6.33331 31.2712 7.64581 32.5837 9.24998 32.5837H26.75C28.3541 32.5837 29.6666 31.2712 29.6666 29.667V12.167L20.9166 3.41699ZM22.375 16.542H16.5416V18.0003H20.9166C21.7187 18.0003 22.375 18.6566 22.375 19.4587V23.8337C22.375 24.6357 21.7187 25.292 20.9166 25.292H19.4583V26.7503H16.5416V25.292H13.625V22.3753H19.4583V20.917H15.0833C14.2812 20.917 13.625 20.2607 13.625 19.4587V15.0837C13.625 14.2816 14.2812 13.6253 15.0833 13.6253H16.5416V12.167H19.4583V13.6253H22.375V16.542Z"
      fill="#8435E9"
    />
  </svg>
))({});

export default InvoiceClaimedIcon;
