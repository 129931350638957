import { FC, memo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePasswordValidation } from '@hooks/usePasswordValidation';
import ResetPasswordChanged from '@pages/ResetPassword/components/ResetPasswordChanged';
import { StyledTypography } from '@pages/ResetPassword/components/styled';
import { StyledBox, StyledLoadingButton } from '@pages/styled';

import { Box, Typography } from '@mui/material';
import InputPassword from '@components/InputPassword/InputPassword';
import PasswordValidationList from '@components/PasswordValidationList';

const ResetPasswordForm: FC<{ loading: boolean; requestSent: boolean }> = ({ loading, requestSent }) => {
  const [isTouched, setIsTouched] = useState(false);
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { passwordErrors, validatePassword } = usePasswordValidation();

  return (
    <Box width="100%">
      <StyledBox>
        {!requestSent && (
          <StyledTypography fontSize={24} fontFamily="WFVisualSans" mb={3}>
            {t('common.resetPasswordTitle')}
          </StyledTypography>
        )}
        {requestSent && <ResetPasswordChanged />}
        {!requestSent && (
          <>
            <Typography mb={2}>{t('common.resetPasswordSubtitle')}</Typography>
            <Box mb={4}>
              <Controller
                control={control}
                render={({ field: { onChange, ...field }, fieldState }) => (
                  <InputPassword
                    fullWidth
                    variant="outlined"
                    label={t('common.inputs.newPassword')}
                    inputProps={{
                      'aria-required': 'true',
                      'aria-label': 'password input field',
                    }}
                    autoComplete="off"
                    error={Boolean(fieldState.error)}
                    helperText={
                      fieldState.error &&
                      t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                    }
                    onChange={e => {
                      validatePassword(e.target.value);
                      onChange(e);

                      if (!isTouched) {
                        setIsTouched(true);
                      }
                    }}
                    {...field}
                  />
                )}
                name="password"
              />
            </Box>
            <PasswordValidationList errors={passwordErrors} isTouched={isTouched} sx={{ mb: 7.5 }} />
            <StyledLoadingButton
              fullWidth
              variant="contained"
              color="primary"
              loading={loading}
              type="submit"
              aria-label="submit button"
            >
              {t('auth.updatePassword')}
            </StyledLoadingButton>
          </>
        )}
      </StyledBox>
    </Box>
  );
};

export default memo(ResetPasswordForm);
