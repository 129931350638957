import { useCallback, useState } from 'react';

export const useHandleChips = () => {
  const [showType, setShowType] = useState(false);
  const [showAutofilled, setShowAutofilled] = useState(false);

  const handleType = useCallback(() => {
    if (!showType && showAutofilled) {
      setShowAutofilled(!showAutofilled);
    }
    setShowType(!showType);
  }, [showAutofilled, showType]);

  const handleAutofilled = useCallback(() => {
    if (showType && !showAutofilled) {
      setShowType(!showType);
    }
    setShowAutofilled(!showAutofilled);
  }, [showAutofilled, showType]);

  return {
    showType,
    showAutofilled,
    handleType,
    handleAutofilled,
  };
};
