import { useTranslation } from 'react-i18next';
import { CONFIG } from '@constants/config';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { StyledCopyButton, StyledCopyTextField } from '@providers/layouts/AppLayout/styled';

import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { StyledModal } from '@/shared/styles';

const InviteModal = NiceModal.create(({ userId }: { userId: number }) => {
  const { t } = useTranslation();
  const modal = useModal();

  const inviteLink = `${CONFIG.domain}/?pk=${userId}`;

  const handleConfirmClick = () => {
    navigator.clipboard.writeText(inviteLink);
    modal.resolve(true);
    modal.remove();
  };
  const handleCancelClick = async () => {
    modal.resolve(false);
    modal.remove();
  };

  return (
    <StyledModal open={modal.visible} size="medium" onClose={handleCancelClick}>
      <Box position="absolute" top={12} right={12}>
        <IconButton disableRipple onClick={handleCancelClick}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box padding="24px 40px" display="flex" flexDirection="column" gap={2}>
        <Typography fontWeight={500} variant="h5" mb="24px" textAlign="center" maxWidth={400} alignSelf="center">
          {t('dashboard.navigation.modal.referTitle')}
        </Typography>
        <Box display="flex" alignItems="center" gap="12px">
          <StyledCopyTextField disabled value={inviteLink} variant="outlined" fullWidth />
          <StyledCopyButton
            disableRipple
            color="secondary"
            onClick={handleConfirmClick}
            startIcon={<ContentCopyIcon />}
          >
            {t('common.copy')}
          </StyledCopyButton>
        </Box>
      </Box>
    </StyledModal>
  );
});

export const InviteModalId = 'InviteModal';

NiceModal.register(InviteModalId, InviteModal);
