import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

const RegistrationHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography color="white" fontSize={32} mb={2} fontFamily="WFVisualSans">
        {t('auth.signupText')}
      </Typography>
      <Typography variant="h5" mb={1} fontFamily="WFVisualSans" color="white">
        {t('auth.signupSubtitle')}
      </Typography>
      <Typography fontWeight={400} mb={5} color="white">
        {t('auth.noCreditDetails')}
      </Typography>
    </>
  );
};

export default RegistrationHeader;
