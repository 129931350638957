import { Fab, FabProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import styled from '@mui/system/styled';

export const StyledFab = styled(Fab)<FabProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.secondary.light,
  color: theme.colors.secondary.contrast,
  borderRadius: '100px',
  width: 'auto',
  padding: '8px 24px',
  '&:hover': {
    backgroundColor: theme.colors.secondary.main,
  },
}));
