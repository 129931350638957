import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { InvoiceRead, useApiInvoicesDestroyMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH, MAX_DESCRIPTION_CHARACTERS } from '@constants/common';
import { ROUTING } from '@constants/routing';
import NiceModal from '@ebay/nice-modal-react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import {
  StyledAutofilledChip,
  StyledHeaderBox,
  StyledInnerCircleBox,
  StyledOuterCircleBox,
  StyledReceivedChip,
  StyledShadowBottomBox,
} from '@pages/InvoiceDetails/components/styled';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetails/context';
import { useHandleChips } from '@pages/InvoiceDetails/hooks/useHandleChips';
import { UndoModalId } from '@pages/InvoiceDetails/modals/UndoModal';
import { StyledTextButton } from '@pages/InvoiceDetails/styled';
import { getAutofillNumber, truncateText } from '@pages/InvoiceDetails/utils';
import { StyledLink } from '@pages/styled';
import { formatCurrency } from '@utils/formatCurrency';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, CircularProgress, Typography } from '@mui/material';

const DetailsHeader: FC<{ data?: InvoiceRead }> = ({ data }) => {
  const { t } = useTranslation();
  const { invoiceId, changesData } = useInvoiceDetailsNewContext();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const { upMd, downMd, upLg } = useBreakpoints();
  const { showAutofilled, showType, handleAutofilled, handleType } = useHandleChips();

  const [handleDestroyInvoice, { isLoading: isLoadingDestroyInvoice }] = useApiInvoicesDestroyMutation();

  const handleDeleteInvoice = async () => {
    try {
      const result = await NiceModal.show(UndoModalId, {
        title: t('dashboard.undoModal.deleteInvoice.title'),
        text: t('dashboard.undoModal.deleteInvoice.text'),
        buttonText: t('dashboard.undoModal.deleteInvoice.buttonText'),
        cancelButtonText: t('common.cancel'),
      });

      if (result) {
        await handleDestroyInvoice({ id: +invoiceId });
        navigate(`/${ROUTING.INVOICES}`, { replace: true });
      }
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  };

  const receivedTypeIcon = data?.is_manual_upload ? <UploadFileIcon /> : <MailOutlineIcon />;
  const receivedTypeLabel = data?.is_manual_upload
    ? t('dashboard.invoiceDetails.manualUpload')
    : t('dashboard.invoiceDetails.emailUpload');

  return (
    <Box
      m={0}
      p={0}
      style={{
        position: downMd ? 'sticky' : 'relative',
        top: downMd ? 100 : 'auto',
        zIndex: 1600,
        backgroundColor: 'white',
      }}
    >
      <StyledHeaderBox p={upLg ? '32px 80px 23px' : '20px'} px={upLg ? '80px' : downMd ? '20px' : '30px'}>
        <StyledLink
          component={RouterLink}
          to={`/${ROUTING.INVOICES}`}
          aria-label="back button"
          sx={{ height: '26px', margin: 0 }}
        >
          <Box display="flex" alignItems="center" gap={0.5} ml="-4px">
            <ChevronLeftIcon />{' '}
            <Typography fontSize={16} fontWeight={500}>
              {t('common.back')}
            </Typography>
          </Box>
        </StyledLink>

        <Typography fontSize={20} mt="14px" fontFamily="WFVisualSans" sx={{ overflowWrap: 'break-word' }}>
          <Typography component="span" fontWeight={500} fontSize={20} fontFamily="WFVisualSans">
            {downMd ? truncateText(MAX_DESCRIPTION_CHARACTERS, data?.description) : data?.description}
          </Typography>{' '}
          <Typography component="span" fontSize={20} fontWeight={400} fontFamily="WFVisualSans">
            for
          </Typography>{' '}
          <Typography component="span" fontSize={20} fontWeight={500} fontFamily="WFVisualSans">
            ${formatCurrency(data?.total_amount)}
          </Typography>
        </Typography>
      </StyledHeaderBox>

      <StyledShadowBottomBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={upMd ? '20px 80px' : '10px 20px'}
        px={upLg ? '80px' : downMd ? '20px' : '30px'}
      >
        <Box display="flex" gap={downMd ? 2 : 3}>
          {/* type chip: manual | email */}
          {!downMd && <StyledReceivedChip icon={receivedTypeIcon} label={receivedTypeLabel} />}
          {downMd && !showType && <StyledOuterCircleBox onClick={handleType}>{receivedTypeIcon}</StyledOuterCircleBox>}
          {downMd && showType && (
            <StyledReceivedChip disableRipple icon={receivedTypeIcon} label={receivedTypeLabel} onClick={handleType} />
          )}

          {/* autofilled chip */}
          <StyledAutofilledChip
            disableRipple
            icon={<StyledInnerCircleBox>{getAutofillNumber(changesData, data)}</StyledInnerCircleBox>}
            label={
              <Typography fontSize={14} display="flex" alignItems="center" gap={1}>
                {!downMd && t('dashboard.invoiceDetails.autoFilledDetails')}
                {downMd && showAutofilled && t('dashboard.invoiceDetails.autoFilledDetails')}
                <AutoAwesomeTwoToneIcon color="primary" fontSize="small" />
              </Typography>
            }
            onClick={handleAutofilled}
          />
        </Box>

        {!isLoadingDestroyInvoice && (
          <StyledTextButton
            disableRipple
            disableElevation
            disableTouchRipple
            disableFocusRipple
            variant="text"
            onClick={handleDeleteInvoice}
            endIcon={<DeleteOutlineIcon color="primary" />}
            color="primary"
            maxWidth="100px"
            sx={{ pr: downMd ? '12px' : '20px', height: 35 }}
          >
            <Typography>{upMd ? t('common.delete') : ''}</Typography>
          </StyledTextButton>
        )}
        {/* ToDo possibly need to rethink disabling UI while delete in process */}
        {isLoadingDestroyInvoice && <CircularProgress size={30} color="primary" />}
      </StyledShadowBottomBox>
    </Box>
  );
};

export default memo(DetailsHeader);
