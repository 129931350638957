import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import useDownloadFile from '@hooks/api/useDownloadFile';
import { useInvoicesContext } from '@pages/Invoices/context';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import { Box, Button, LinearProgress } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import LoadingButton from '@components/LoadingButton';

import { StyledDocViewerWrapper } from '../styled';

import '@cyntler/react-doc-viewer/dist/index.css';

export interface DocumentResponse {
  blob: Blob;
  filename: string;
}

const PreviewInvoice = () => {
  const [docs, setDocs] = useState<Array<DocumentResponse>>([]);
  const { t } = useTranslation();
  const { toggleDrawer, open, documentId, setDocumentId } = useInvoicesContext();
  const { isLoadingDownload, isLoadingPreview, downloadFile, downloadPreview, cancelRequest } = useDownloadFile();
  const snackbar = useSnackbar();

  const handleDownload = async () => {
    await downloadFile({ id: documentId as number });
    toggleDrawer();
  };
  const handleCancelClick = async () => {
    cancelRequest();
    toggleDrawer();
    setDocumentId(null);
  };

  useEffect(() => {
    if (documentId) {
      const fetchDocument = async () => {
        try {
          const res = await downloadPreview({ id: documentId as number });
          if (res) {
            setDocs([res]);
          }
        } catch (error) {
          snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH), { variant: ERROR });
        }
      };

      fetchDocument();
    }
  }, [documentId]);

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={handleCancelClick}
      ModalProps={{ keepMounted: true }}
      PaperProps={{
        sx: { width: '70%' },
      }}
    >
      <Box padding="24px 48px" minWidth={560}>
        <Box display="flex" justifyContent="space-between">
          <Button color="secondary" onClick={handleCancelClick}>
            {t('common.back')}
          </Button>
          <LoadingButton
            disabled={isLoadingPreview}
            loading={isLoadingDownload}
            variant="contained"
            color="success"
            onClick={handleDownload}
          >
            {t('common.download')}
          </LoadingButton>
        </Box>
        <Box mt={1} minHeight={300}>
          {isLoadingPreview ? (
            <LinearProgress />
          ) : (
            <StyledDocViewerWrapper>
              <DocViewer
                pluginRenderers={DocViewerRenderers}
                documents={docs.map(file => ({
                  uri: window.URL.createObjectURL(file.blob),
                  fileName: file.filename,
                }))}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: true,
                  },
                }}
                style={{ height: 700 }}
              />
            </StyledDocViewerWrapper>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default memo(PreviewInvoice);
