/* eslint-disable @typescript-eslint/no-shadow */
import { createTheme } from '@mui/material';

import { colors, error, primary, secondary, success } from './colors';
import PoppinsMedium from './fonts/Poppins-Medium.ttf'; // 500
import PoppinsRegular from './fonts/Poppins-Regular.ttf'; // 400
import PoppinsSemiBold from './fonts/Poppins-SemiBold.ttf'; // 600
import WFVisualSansMedium from './fonts/WFVisualSans-Medium.woff2'; // 500
import WFVisualSansRegular from './fonts/WFVisualSans-Regular.woff2'; // 400
import { MuiAppBar } from './MuiAppBar';
import { MuiButton } from './MuiButton';
import { MuiTextField } from './MuiTextField';
import { typography } from './typography';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipOwnProps {
    disableRipple?: boolean;
  }
}

export const important = (cssValue: string | number) => `${cssValue} !important`;

export const theme = createTheme({
  colors,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1728,
    },
  },
  palette: {
    // blue
    primary: {
      main: primary.main,
      dark: primary.dark,
      light: primary.light,
      contrastText: colors.white,
    },
    // dark
    secondary: {
      main: secondary.main,
      dark: secondary.dark,
      light: secondary.light,
      contrastText: colors.white,
    },
    success: {
      main: success.main,
      dark: success.dark,
      light: success.light,
    },
    error: {
      main: error.main,
      dark: error.dark,
      light: error.light,
    },
  },
  typography,
  components: {
    MuiAppBar,
    MuiButton,
    MuiTextField,
    MuiCssBaseline: {
      styleOverrides: theme => `
        @font-face {
          font-family: 'WFVisualSans';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('WFVisualSansMedium'), local('WFVisualSansMedium'), url(${WFVisualSansMedium}) format('opentype');
        }
        
        @font-face {
          font-family: 'WFVisualSans';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('WFVisualSansRegular'), local('WFVisualSansRegular'), url(${WFVisualSansRegular}) format('opentype');
        }
        
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('PoppinsRegular'), local('PoppinsRegular'), url(${PoppinsRegular}) format('opentype');
        }
        
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('PoppinsMedium'), local('PoppinsMedium'), url(${PoppinsMedium}) format('opentype');
        }
        
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('PoppinsSemiBold'), local('PoppinsSemiBold'), url(${PoppinsSemiBold}) format('opentype');
        }

        body {
          font-family: 'Poppins', 'WFVisualSans', 'Helvetica', 'sans-serif';
          color: ${theme.colors.text}
        }
        .MuiPopper-root {
          z-index: 2000 !important;
        }
        .MuiPopover-root {
          z-index: 2000 !important;
        }
      `,
    },
  },
});

theme.shadows.unshift('0px 26px 58px 0px rgba(0,0,0,0.22), 0px 5px 14px 0px rgba(0,0,0,0.18);');
