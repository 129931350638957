import { Box, BoxProps, Button, ButtonProps, Paper, PaperProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledPrimaryBox = styled(Box)<BoxProps & { isSelected?: boolean }>(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? theme.colors.primary.main : theme.colors.background.secondaryBackground,
  color: isSelected ? 'white' : theme.colors.primary.main,
  borderRadius: isSelected ? 'none' : '5px 5px 0px 0px',
  borderBottom: `1px solid ${theme.colors.primary.main}`,
}));

export const CardContainer = styled(Paper)<PaperProps & { elevation: number; isSelected: boolean }>(
  ({ theme, elevation, isSelected }) => ({
    width: '100%',
    border: `${isSelected ? '2px' : '1px'} solid ${theme.colors.primary.main}`,
    borderRadius: '5px',
    boxShadow: elevation ? `${theme.shadows[elevation]}` : 'none',
    cursor: 'pointer',
  }),
);

export const StyledSummaryBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.secondary.contrast,
  color: theme.colors.white,
}));

export const StyledLogoutButton = styled(Button)<ButtonProps>(({ theme }) => ({
  boxShadow: 'none',
  minWidth: '130px',
  color: theme.colors.primary.contrast,
}));

export const StyledBoxContainer = styled(Box)({
  background: 'linear-gradient(to top,#FDC15E1F, #F5A57540, #ED898B80, #A354AFBF, #8435E9, #5A1ED3)',
});
