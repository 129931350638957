import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useShrink } from '@hooks/useShrink';
import { StyledTextField } from '@pages/Invoices/styled';

import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';

const SearchField: FC = () => {
  const { t } = useTranslation();
  const searchShrink = useShrink();

  return (
    <StyledTextField
      variant="outlined"
      inputProps={{
        'aria-label': 'search input field',
      }}
      InputLabelProps={{
        shrink: searchShrink.shrink,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      label={t('dashboard.search')}
      onFocus={() => searchShrink.setShrink(true)}
      onBlur={e => {
        !e.target.value && searchShrink.setShrink(false);
      }}
    />
  );
};

export default memo(SearchField);
