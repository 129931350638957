import { Box, Typography } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledList = styled(Box)({
  paddingLeft: 0,
  margin: 0,
});

export const StyledListTypography = styled(Typography, {
  shouldForwardProp: (propName: PropertyKey) => !['isError', 'isValid'].includes(propName as string),
})<{
  theme?: Theme;
  isError: boolean;
  isValid: boolean;
  component?: string;
}>(({ isValid, theme }) => ({
  display: 'block',
  color: isValid ? theme.colors.success.dark : theme.colors.black,
}));
