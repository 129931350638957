import { Paper, PaperProps, Theme } from '@mui/material';
import styled from '@mui/system/styled';

export const StyledPaper = styled(Paper)<PaperProps & { theme?: Theme }>(({ theme }) => ({
  padding: '64px 0',
  backgroundColor: theme.colors.background.paperElevation0,
  width: '60%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '18px',
  flexDirection: 'column',
}));
