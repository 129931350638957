import { useSearchParams } from 'react-router-dom';

export const useUidQueryParam = () => {
  const [uidSearchParams, setUidSearchParams] = useSearchParams();

  const uid = uidSearchParams.get('uid') || '';

  return {
    uid,
    removeUidParam: () => {
      uidSearchParams.delete('uid');
      setUidSearchParams(uidSearchParams, { replace: true });
    },
  };
};
