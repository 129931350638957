import { FC, memo, MouseEvent } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetails/context';
import { PreviewModalId } from '@pages/InvoiceDetails/modals/PreviewModal';

import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';

const DocumentWidget: FC = () => {
  const { fileBlob, fileName, fileType } = useInvoiceDetailsNewContext();

  const isPdf = fileType?.includes('pdf');
  const docs = [{ blob: fileBlob as Blob, fileName: fileName as string }];

  const handlePreview = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    // ToDo need fix for multipage pdf file to pass selectedPage; for now will pass first page only
    await NiceModal.show(PreviewModalId, { docs, selectedPage: 1, isPdf });
  };

  const handleDownload = () => {
    if (fileBlob) {
      const url = window.URL.createObjectURL(fileBlob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}`;
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <IconButton onClick={handleDownload} disabled={!fileBlob}>
        <DownloadIcon color={fileBlob ? 'primary' : 'disabled'} fontSize="small" />
      </IconButton>

      <IconButton onClick={handlePreview} disabled={!fileBlob}>
        <VisibilityIcon color={fileBlob ? 'primary' : 'disabled'} fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default memo(DocumentWidget);
