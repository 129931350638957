import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ICON_TYPE } from '@pages/InvoiceDetails/components/EventsTab/constants';
import { StyledAddButton, StyledEventBox } from '@pages/InvoiceDetails/components/EventsTab/styled';
import { EventDetails } from '@pages/InvoiceDetails/components/EventsTab/types';
import { ICONS_SVG_MAP } from '@pages/InvoiceDetails/components/EventsTab/utils';

import { StyledTypographyContrast } from '@/shared/styles';

const InvoiceEvent: FC<EventDetails> = ({ iconType, title, onAdd }) => {
  const { t } = useTranslation();
  const IconComponent = ICONS_SVG_MAP[iconType];

  return (
    <StyledEventBox
      display="flex"
      gap={2.5}
      isCustom={iconType === ICON_TYPE.CUSTOM}
      maxWidth={{
        xs: '100%',
        sm: '560px',
        md: '406px',
        lg: '505px',
      }}
    >
      <IconComponent color="primary" />
      <StyledTypographyContrast fontFamily="WFVisualSans" flexGrow={2}>
        {t(title)}
      </StyledTypographyContrast>
      <StyledAddButton disableRipple disableTouchRipple disableFocusRipple onClick={onAdd}>
        {t('common.add')}
      </StyledAddButton>
    </StyledEventBox>
  );
};

export default memo(InvoiceEvent);
