import { Link, LinkProps } from 'react-router-dom';

import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import {
  Box,
  BoxProps,
  Paper,
  PaperProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledBox = styled(Box)<BoxProps>({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
});

export const StyledPaper = styled(Paper)<PaperProps>(() => ({
  borderRadius: '10px',
}));

export const StyledTextField = styled(TextField)<TextFieldProps>({
  appearance: 'none',
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
});

export const StyledLoadingButton = styled(LoadingButton)<LoadingButtonProps>({
  padding: '10px 15px',
  fontWeight: 500,
});

export const StyledLink = styled(Link)<LinkProps & { component?: typeof Link }>(({ theme }) => ({
  color: theme.colors.primary.main,
  fontWeight: 600,
  fontSize: '12px',
  textDecoration: 'none',
  margin: '10px 0',
}));

export const StyledBoldText = styled(Typography)<TypographyProps>({
  fontWeight: 700,
});

export const StyledAreaBox = styled(Box)<BoxProps & { theme?: Theme; active?: boolean; maxWidth?: string }>(
  ({ theme, active = false, maxWidth }) => ({
    maxWidth: maxWidth || '580px',
    padding: '24px',
    borderRadius: '12px',
    border: `1px solid ${active ? theme.colors.primary.main : theme.colors.background.disabledButton}`,
  }),
);

export const StyledOverflowHiddenTypography = styled(Typography)<TypographyProps>({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
});

export const StyledRegularTextField = styled(TextField)<TextFieldProps>({
  '& .MuiInputLabel-root': {
    fontWeight: 400,
  },
  '& .MuiInputBase-root': {
    fontWeight: 400,
  },
  appearance: 'none',
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
});
