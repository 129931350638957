import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledAddNoteButton, StyledEmptyStateBox } from '@pages/InvoiceDetails/components/NotesTab/styled';

import AddIcon from '@mui/icons-material/Add';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { Typography } from '@mui/material';

const EmptyNotes: FC<{ handleAdd: () => void }> = ({ handleAdd }) => {
  const { t } = useTranslation();

  return (
    <StyledEmptyStateBox gap={1}>
      <StickyNote2Icon color="disabled" fontSize="large" />
      <Typography fontWeight={400} color="textSecondary">
        {t('dashboard.notes.emptyStateCta')}
      </Typography>
      <StyledAddNoteButton endIcon={<AddIcon />} variant="text" color="primary" onClick={handleAdd}>
        <Typography fontSize={14}>{t('dashboard.notes.addNoteBtnText')}</Typography>
      </StyledAddNoteButton>
    </StyledEmptyStateBox>
  );
};

export default memo(EmptyNotes);
