import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { StyledTypography } from '@pages/ResetPassword/components/styled';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Box, Button } from '@mui/material';

const ResetPasswordChanged = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${ROUTING.LOGIN}`, { replace: true });
  };

  return (
    <Box pt="45px">
      <Box display="flex" gap={1.5} alignItems="center" mb={9}>
        <StyledTypography fontSize={20} fontFamily="WFVisualSans">
          {t('auth.resetPasswordFinishTitle')}
        </StyledTypography>
        <TaskAltIcon color="primary" />
      </Box>

      <Button
        fullWidth
        disableRipple
        disableTouchRipple
        disableFocusRipple
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        {t('common.goToLogin')}
      </Button>
    </Box>
  );
};

export default memo(ResetPasswordChanged);
