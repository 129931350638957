export const CONFIG = {
  domain: 'https://www.capsure.com.au',
  pages: {
    termsOfService: '/#',
    privacyPolicy: '/#',
    contact: '#',
    howToUse: '/how-to-use',
    claimWizard: '/claim-wizard',
    support: '/support',
    copyright: '/#',
    privacy: '/#',
  },
};

export const STRIPE = {
  dev: {
    apiKey:
      'pk_test_51PbKsA2M9dyMzKgHT8s1hLbx5euAYLCrqstUKOm8bK8qDEVcLQ4vlN9eYqH95xWcbwoV9IPUKwZCEJkZ6DiTSXDk001nCw1DdR',
  },
};
