import { BillingPeriod } from '@constants/enums';
import { CardProps } from '@pages/SubscribePage/types';

export const cardsConfig: CardProps[] = [
  {
    id: 1,
    config: {
      text: 'dashboard.subscribePage.plans.annually.text',
      title: 'dashboard.subscribePage.plans.annually.title',
      cta: 'dashboard.subscribePage.plans.annually.cta',
      period: 'dashboard.subscribePage.plans.annually.period',
      billed: 'dashboard.subscribePage.plans.annually.billed',
      price: 280,
    },
    plan: BillingPeriod.YEAR,
  },
  {
    id: 2,
    config: {
      text: 'dashboard.subscribePage.plans.monthly.text',
      title: 'dashboard.subscribePage.plans.monthly.title',
      cta: 'dashboard.subscribePage.plans.monthly.cta',
      period: 'dashboard.subscribePage.plans.monthly.period',
      billed: 'dashboard.subscribePage.plans.monthly.billed',
      price: 35,
    },
    plan: BillingPeriod.MONTH,
  },
];
