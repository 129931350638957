import styled from '@mui/system/styled';

const ErrorIcon = styled(props => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.00033 0.666748C4.39199 0.666748 0.666992 4.39175 0.666992 9.00008C0.666992 13.6084 4.39199 17.3334 9.00033 17.3334C13.6087 17.3334 17.3337 13.6084 17.3337 9.00008C17.3337 4.39175 13.6087 0.666748 9.00033 0.666748ZM13.167 11.9917L11.992 13.1667L9.00033 10.1751L6.00866 13.1667L4.83366 11.9917L7.82533 9.00008L4.83366 6.00841L6.00866 4.83342L9.00033 7.82508L11.992 4.83342L13.167 6.00841L10.1753 9.00008L13.167 11.9917Z"
      fill="#D32F2F"
    />
  </svg>
))({});

export default ErrorIcon;
