import { Dispatch, FC, memo, SetStateAction, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InvoiceRead } from '@api/api';
import { MAX_DESCRIPTION_CHARACTERS } from '@constants/common';
import { useUser } from '@hooks/api/useUser';
import MissingDetailsDropdowns from '@pages/InvoiceDetails/components/DetailsTab/components/MissingDetailsDropdowns';
import {
  StyledAlertTextField,
  StyledAmountTextField,
  StyledFormControl,
  StyledInputLabel,
  StyledIssueLoadingButton,
  StyledSelect,
} from '@pages/InvoiceDetails/components/DetailsTab/styled';
import {
  MISSING_DESCRIPTION_FIELD,
  MISSING_PARTICIPANT_FIELD,
  MISSING_TOTAL_AMOUNT_FIELD,
  MISSING_VENDOR_NAME_FIELD,
  VENDOR_NAME_FIELD,
} from '@pages/InvoiceDetails/constants';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetails/context';
import { formatCurrency } from '@utils/formatCurrency';

import { Box, InputAdornment, MenuItem, Typography } from '@mui/material';

const MissingDetailsForm: FC<{
  loading: boolean;
  data?: InvoiceRead;
  setShouldShowDescription: Dispatch<SetStateAction<boolean | null>>;
  shouldShowDescription: boolean | null;
}> = ({ loading, data, shouldShowDescription, setShouldShowDescription }) => {
  const { t } = useTranslation();
  const { control, setError, trigger, watch, formState } = useFormContext();
  const missingDescField = watch(MISSING_DESCRIPTION_FIELD);
  const missingProviderField = watch(MISSING_VENDOR_NAME_FIELD);

  const { data: userData } = useUser();
  const { supportCategory, isReason } = useInvoiceDetailsNewContext();

  useEffect(() => {
    if (missingDescField?.length > MAX_DESCRIPTION_CHARACTERS) {
      setError(MISSING_DESCRIPTION_FIELD, {
        type: 'manual',
        message: `${missingDescField.length - MAX_DESCRIPTION_CHARACTERS} characters over the ${MAX_DESCRIPTION_CHARACTERS} character limit.`,
      });
      trigger(MISSING_DESCRIPTION_FIELD);
    }
    if (missingProviderField?.length > MAX_DESCRIPTION_CHARACTERS) {
      setError(VENDOR_NAME_FIELD, {
        type: 'manual',
        message: `${missingProviderField.length - MAX_DESCRIPTION_CHARACTERS} characters over the ${MAX_DESCRIPTION_CHARACTERS} character limit.`,
      });
      trigger(VENDOR_NAME_FIELD);
    }
  }, [missingDescField, missingProviderField, setError, trigger]);

  const hasInitialError = Object.keys(formState.errors).includes(MISSING_DESCRIPTION_FIELD);
  const noInitialDescription = !data?.description;

  useEffect(() => {
    if (!shouldShowDescription) {
      setShouldShowDescription(hasInitialError || noInitialDescription);
    }
  }, [
    data?.description,
    formState.errors,
    hasInitialError,
    noInitialDescription,
    setShouldShowDescription,
    shouldShowDescription,
  ]);

  return (
    <Box>
      <MissingDetailsDropdowns />
      <Box display="flex" flexDirection="column" gap={2} mt={!supportCategory || (!data?.abn && !isReason) ? 2 : 0}>
        {!!shouldShowDescription && (
          <Controller
            control={control}
            name={MISSING_DESCRIPTION_FIELD}
            render={({ field, fieldState }) => {
              const charsOverLimit = field.value ? Math.max(0, field.value.length - MAX_DESCRIPTION_CHARACTERS) : 0;

              const isMaxLengthError = field.value?.length > MAX_DESCRIPTION_CHARACTERS;

              return (
                <Box display="flex" flexDirection="column" gap={2} width="100%" justifyContent="center">
                  {fieldState.error ? (
                    <Typography fontWeight={400}>{t(fieldState.error.message as string)}</Typography>
                  ) : (
                    <Box display="flex">
                      <Typography fontWeight={400}>{t('dashboard.updateDetails.descriptionHelpText')}</Typography>
                    </Box>
                  )}
                  <StyledAlertTextField
                    fullWidth
                    variant="outlined"
                    label={t('dashboard.updateDetails.descriptionTitle')}
                    type="text"
                    autoComplete="off"
                    inputProps={{
                      'aria-required': 'true',
                      'aria-label': 'description input field',
                    }}
                    error={Boolean(fieldState.error)}
                    helperText={
                      fieldState.error ? (
                        isMaxLengthError ? (
                          `${charsOverLimit} characters over maximum`
                        ) : (
                          ''
                        )
                      ) : (
                        <Box display="flex" justifyContent="end">
                          <Typography variant="caption">
                            {`${field?.value?.length || 0}/${MAX_DESCRIPTION_CHARACTERS}`}
                          </Typography>
                        </Box>
                      )
                    }
                    {...field}
                    onBlur={() => {
                      trigger(MISSING_DESCRIPTION_FIELD);
                    }}
                  />
                </Box>
              );
            }}
          />
        )}

        {!data?.vendor_name && (
          <Controller
            control={control}
            name={MISSING_VENDOR_NAME_FIELD}
            render={({ field, fieldState }) => {
              const charsOverLimit = field.value ? Math.max(0, field.value.length - MAX_DESCRIPTION_CHARACTERS) : 0;

              const isMaxLengthError = field.value?.length > MAX_DESCRIPTION_CHARACTERS;

              return (
                <Box display="flex" flexDirection="column" justifyContent="center" gap={2} width="100%">
                  {fieldState.error ? (
                    <Typography fontWeight={400}>{t(fieldState.error.message as string)}</Typography>
                  ) : (
                    <Box display="flex">
                      <Typography fontWeight={400}>{t('dashboard.updateDetails.providerNameHelpText')}</Typography>
                    </Box>
                  )}
                  <StyledAlertTextField
                    fullWidth
                    variant="outlined"
                    label={t('dashboard.updateDetails.providerTitle')}
                    type="text"
                    autoComplete="off"
                    inputProps={{
                      'aria-required': 'true',
                      'aria-label': 'provider input field',
                    }}
                    error={Boolean(fieldState.error)}
                    helperText={
                      fieldState.error ? (
                        isMaxLengthError ? (
                          `${charsOverLimit} characters over maximum`
                        ) : (
                          ''
                        )
                      ) : (
                        <Box display="flex" justifyContent="end">
                          <Typography variant="caption">
                            {`${field?.value?.length || 0}/${MAX_DESCRIPTION_CHARACTERS}`}
                          </Typography>
                        </Box>
                      )
                    }
                    {...field}
                    onBlur={() => {
                      trigger(MISSING_VENDOR_NAME_FIELD);
                    }}
                  />
                </Box>
              );
            }}
          />
        )}

        {(!data?.total_amount || Number(data?.total_amount) === 0) && (
          <Controller
            control={control}
            name={MISSING_TOTAL_AMOUNT_FIELD}
            render={({ field, fieldState }) => (
              <Box display="flex" flexDirection="column" justifyContent="center" gap={2} width="100%">
                {fieldState.error ? (
                  <Typography fontWeight={400}>{t(fieldState.error.message as string)}</Typography>
                ) : (
                  <Box display="flex">
                    <Typography fontWeight={400}>{t('dashboard.invoiceDetails.totalAmountHelpTitle')}</Typography>
                  </Box>
                )}
                <StyledAmountTextField
                  fullWidth
                  variant="outlined"
                  label={t('dashboard.updateDetails.totalAmountTitle')}
                  type="text"
                  autoComplete="off"
                  inputProps={{
                    'aria-required': 'true',
                    'aria-label': 'total amount input field',
                    style: { fontWeight: 400 },
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  error={Boolean(fieldState.error)}
                  {...field}
                  onChange={e => {
                    const rawValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and decimal
                    field.onChange(rawValue);
                  }}
                  onBlur={() => {
                    if (field.value) {
                      field.onChange(formatCurrency(field.value));
                    }
                  }}
                  onFocus={() => {
                    if (field.value) {
                      const rawValue = field.value.replace(/[^0-9.]/g, '');
                      field.onChange(rawValue);
                    }
                  }}
                />
              </Box>
            )}
          />
        )}

        {!data?.participant && (
          <Controller
            control={control}
            name={MISSING_PARTICIPANT_FIELD}
            render={({ field }) => (
              <Box>
                <Typography fontWeight={400} mb={2}>
                  {t('dashboard.updateDetails.participantHelpText')}
                </Typography>
                <StyledFormControl fullWidth>
                  <StyledInputLabel id={`${MISSING_PARTICIPANT_FIELD}-select-label`}>
                    {t('dashboard.updateDetails.participantTitle')}
                  </StyledInputLabel>
                  <StyledSelect
                    labelId={`${MISSING_PARTICIPANT_FIELD}-select-label`}
                    id={`${MISSING_PARTICIPANT_FIELD}-select`}
                    label={t('dashboard.updateDetails.participantTitle')}
                    {...field}
                  >
                    {userData?.participants?.map(({ id, name }) => (
                      <MenuItem key={id} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </StyledFormControl>
              </Box>
            )}
          />
        )}

        <StyledIssueLoadingButton type="submit" color="primary" loading={loading} variant="contained">
          {t('common.save')}
        </StyledIssueLoadingButton>
      </Box>
    </Box>
  );
};

export default memo(MissingDetailsForm);
