import { FC, PropsWithChildren } from 'react';
import { useUser } from '@hooks/api/useUser';

import { Box } from '@mui/material';

const PrivatePreviewLayout: FC<PropsWithChildren> = ({ children }) => {
  const { data: user } = useUser();
  const hasRights = user;

  return <Box style={!hasRights ? { visibility: 'hidden' } : undefined}>{hasRights ? children : null}</Box>;
};

export default PrivatePreviewLayout;
