import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import EmailIcon from '@mui/icons-material/Email';
import PaidIcon from '@mui/icons-material/Paid';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import UploadIcon from '@mui/icons-material/Upload';

export const ICONS_SVG_MAP = {
  CUSTOM: DashboardCustomizeIcon,
  SUBMITTED: DomainVerificationIcon,
  PROVIDER_PAID: CheckCircleIcon,
  FUNDS: PaidIcon,
  EMAIL: EmailIcon,
  DUE: PriorityHighIcon,
  UPLOADED: UploadIcon,
};
