import React, { Component, ErrorInfo, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import AppLayout from '@providers/layouts/AppLayout';
import { useSnackbar } from 'notistack';

import { Box, Button, Typography } from '@mui/material';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

const ErrorFallbackDefault: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleReload = () => window.location.reload();

  React.useEffect(() => {
    enqueueSnackbar('Oops, something went wrong', {
      variant: 'warning',
      preventDuplicate: true,
    });
  }, [enqueueSnackbar]);

  return (
    <AppLayout>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="32px" padding="24px">
        <Typography variant="h3" fontWeight="500">
          {t('common.somethingWentWrong')}
        </Typography>
        <Button onClick={handleReload}>{t('common.reloadPage')}</Button>
      </Box>
    </AppLayout>
  );
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    console.error(error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorFallbackDefault />;
    }

    return children;
  }
}

export default ErrorBoundary;
