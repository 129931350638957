import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';

import { Box, Button, Typography } from '@mui/material';

import { StyledPaper } from './styled';

const NotFound: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoBack = () => navigate(ROUTING.ROOT);

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.notFound')}</title>
      </Helmet>
      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        minHeight="100vh"
        aria-label="page 404 not found"
      >
        <StyledPaper>
          <Typography variant="h1" color="text.secondary">
            {t('common.notFound.title')}
          </Typography>
          <Typography variant="caption" textAlign="center" minWidth="200px" maxWidth="400px">
            {t('common.notFound.subtitle')}
          </Typography>
          <Button onClick={handleGoBack} aria-label="go back button">
            {t('common.goBack')}
          </Button>
        </StyledPaper>
      </Box>
    </>
  );
};

export default memo(NotFound);
