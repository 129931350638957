import { FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { ForgotPasswordDto } from '@pages/ForgotPassword';
import { StyledBox, StyledLink, StyledLoadingButton } from '@pages/styled';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Typography } from '@mui/material';

import { StyledTextField, StyledTypography } from './styled';

const ForgotPasswordForm: FC<{ loading: boolean; requestSent: boolean }> = ({ loading, requestSent }) => {
  const { t } = useTranslation();
  const { control } = useFormContext<ForgotPasswordDto>();

  return (
    <>
      <Box mb={3}>
        <StyledLink component={RouterLink} to={`/${ROUTING.LOGIN}`} replace aria-label="back to login button">
          <Box display="flex" alignItems="center" gap={2}>
            <ChevronLeftIcon /> <Typography fontSize={15}>{t('common.backToLogin')}</Typography>
          </Box>
        </StyledLink>
      </Box>
      <StyledTypography fontSize={24} mb={2} aria-label={t('common.forgotPassword')} fontFamily="WFVisualSans">
        {requestSent ? t('common.forgotPasswordSent') : t('common.forgotPassword')}
      </StyledTypography>
      <Typography mb={4} fontWeight={400}>
        {requestSent ? t('auth.forgotPasswordFinishTitle') : t('common.forgotPasswordSubtitle')}
      </Typography>
      <StyledBox>
        {!requestSent && (
          <>
            <Typography aria-label={t('common.forgotPasswordCaption')} mb={2}>
              {t('common.forgotPasswordCaption')}
            </Typography>
            <Box width="100%" mb={7.5}>
              <Controller
                control={control}
                render={({ field, fieldState }) => (
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    label={t('common.inputs.email')}
                    type="email"
                    error={Boolean(fieldState.error)}
                    inputProps={{
                      'aria-required': 'true',
                      'aria-label': 'email input field',
                    }}
                    autoComplete="off"
                    helperText={
                      fieldState.error &&
                      t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                    }
                    {...field}
                  />
                )}
                name="email"
              />
            </Box>
            <StyledLoadingButton
              fullWidth
              variant="contained"
              color="primary"
              loading={loading}
              type="submit"
              aria-label="submit button"
            >
              <Typography>{t('auth.submitForgotBtnText')}</Typography>
            </StyledLoadingButton>
          </>
        )}
      </StyledBox>
    </>
  );
};

export default memo(ForgotPasswordForm);
