import { useTranslation } from 'react-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { StyledTextButton } from '@pages/InvoiceDetails/styled';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { StyledDeleteModalButton, StyledModal } from '../styled';

const DeleteNoteModal = NiceModal.create(
  ({
    title,
    buttonText,
    cancelButtonText,
  }: {
    title: string;
    text: string;
    buttonText: string;
    cancelButtonText?: string;
  }) => {
    const { t } = useTranslation();
    const modal = useModal();

    const handleConfirmClick = () => {
      modal.resolve(true);
      modal.remove();
    };
    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    return (
      <StyledModal open={modal.visible} size="small" onClose={handleCancelClick}>
        <Box paddingTop="60px" px="25.5px">
          <Box maxWidth={275}>
            <Box position="absolute" top={10} right={12}>
              <IconButton onClick={handleCancelClick}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography fontWeight={400}>{t(title)}</Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" pb="35px" mt="54px">
            <StyledTextButton
              disableRipple
              disableElevation
              disableTouchRipple
              disableFocusRipple
              variant="text"
              onClick={handleCancelClick}
              sx={{ height: 42, width: 82 }}
            >
              {cancelButtonText}
            </StyledTextButton>
            <StyledDeleteModalButton
              disableRipple
              disableTouchRipple
              disableFocusRipple
              variant="contained"
              color="primary"
              onClick={handleConfirmClick}
            >
              {buttonText}
            </StyledDeleteModalButton>
          </Box>
        </Box>
      </StyledModal>
    );
  },
);

export const DeleteNoteModalId = 'DeleteNoteModal';

NiceModal.register(DeleteNoteModalId, DeleteNoteModal);
