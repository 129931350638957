import { AccordionDetailsProps, AccordionProps, AccordionSummaryProps, Box, BoxProps } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import LoadingButton from '@components/LoadingButton';

export const StyledIssueAccordion = styled(Accordion)<AccordionProps>(({ expanded }) => ({
  border: '1px solid black',
  '& .MuiAccordion-heading .MuiButtonBase-root': {
    backgroundColor: '#FFF9F0',
    borderRadius: '5px',
    borderBottom: expanded ? '1px solid black' : 'none',
    borderBottomRightRadius: expanded ? 'inherit' : '5px',
    borderBottomLeftRadius: expanded ? 'inherit' : '5px',
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)<AccordionSummaryProps>(() => ({
  display: 'flex',
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginRight: '5px',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    marginRight: '5px',
  },
  paddingLeft: '30px !important',
  paddingRight: '24px !important',
}));

export const StyledIssueAccordionDetails = styled(AccordionDetails)<AccordionDetailsProps>(() => ({
  padding: '40px 30px',
}));

export const StyledAccordionInnerBox = styled(Box)<BoxProps>(({ theme }) => ({
  borderRadius: '50%',
  height: 20,
  width: 20,
  color: theme.colors.secondary.contrast, // secondary.contrast
  backgroundColor: theme.colors.secondary.main, // secondary.main
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledIssueLoadingButton = styled(LoadingButton)({
  padding: '6px 16px',
  width: 150,
});
