import { FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyledAlertAutocomplete, StyledTitle } from '@pages/InvoiceDetails/components/DetailsTab/styled';

import { Box, TextField } from '@mui/material';
import AutocompletePaper from '@components/AutocompletePaper';

import { Option } from '@/globalTypes';

interface AlertDropdownProps {
  options: Option[];
  title: string;
  placeholder: string;
  name: string;
}

const AlertDropdown: FC<AlertDropdownProps> = ({ options, title, placeholder, name }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Box width="100%">
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <Box display="flex" flexDirection="column" gap={2}>
            <StyledTitle>{title}</StyledTitle>
            <Box display="flex" gap={2} width="100%" alignItems="center">
              <StyledAlertAutocomplete
                {...field}
                options={options}
                disablePortal
                PaperComponent={AutocompletePaper}
                renderInput={params => (
                  <TextField
                    error={Boolean(fieldState.error)}
                    variant="outlined"
                    placeholder={placeholder}
                    helperText={
                      fieldState.error &&
                      t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                    }
                    {...params}
                  />
                )}
                onChange={(_, d) => field.onChange(d)}
              />
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

export default memo(AlertDropdown);
