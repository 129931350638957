import { AuthSignupVerifyEmailCreateApiResponse, JwtRead } from '@api/api';
import { USER_ACCESS_TOKEN, USER_REFRESH_TOKEN } from '@constants/auth';
import { tokenStorage } from '@utils/tokenStorage';

export const saveToStorage = (rememberMe: boolean, response: JwtRead | AuthSignupVerifyEmailCreateApiResponse) => {
  if (rememberMe) {
    tokenStorage.setToken(USER_ACCESS_TOKEN, response.access);
    tokenStorage.setToken(USER_REFRESH_TOKEN, response.refresh);
  } else {
    tokenStorage.setToken(USER_ACCESS_TOKEN, response.access, true);
    tokenStorage.setToken(USER_REFRESH_TOKEN, response.refresh, true);
  }
};

export const getToken = (token: string) => {
  const sessionToken = sessionStorage.getItem(token);
  if (!sessionToken) {
    return localStorage.getItem(token);
  }
  return sessionToken;
};
