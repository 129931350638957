import * as yup from 'yup';

export const schema = yup.object().shape({
  participant: yup.boolean().nullable(),
  nominee: yup.boolean().nullable(),
  childRepresentative: yup.boolean().nullable(),
  data: yup.array().of(
    yup.object().shape({
      participantName: yup.string().nullable()
    })
  ),
}).test(
  'at-least-one-checkbox',
  'At least one option must be selected',
  // @ts-ignore
  (values) => values.participant || values.nominee || values.childRepresentative
)
