import { FC, memo } from 'react';
import ReactQuill from 'react-quill';
import { MAX_NOTE_CHARACTERS } from '@constants/common';

import { Typography } from '@mui/material';

import 'react-quill/dist/quill.snow.css';
import './QuillEditor.css';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ align: ['right', 'center', 'justify'] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    [{ color: ['#000', '#8435E9', '#25175F'] }],
  ],
};

const formats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'link', 'color', 'align'];

const QuillEditor: FC<{ text: string; handleChange: (text: string) => void }> = ({ text, handleChange }) => (
    <>
      <ReactQuill
        onChange={handleChange}
        theme="snow"
        modules={modules}
        formats={formats}
        value={text}
        className="custom-quill-editor"
      />
      <Typography
        textAlign="end"
        color={text.length > MAX_NOTE_CHARACTERS ? 'error' : 'textSecondary'}
        fontSize={12}
        fontWeight={400}
      >{`${text?.length || 0}/${MAX_NOTE_CHARACTERS}`}</Typography>
    </>
  );

export default memo(QuillEditor);
