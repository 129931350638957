import { useSearchParams } from 'react-router-dom';

export const useSessionId = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id') || '';

  return {
    sessionId,
    setSessionId: (value: string) => {
      searchParams.set('session_id', String(value));
      setSearchParams(searchParams);
    },
    removeSessionId: () => {
      searchParams.delete('session_id');
      setSearchParams(searchParams);
    },
  };
};
