import { FC, memo, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { useBreakpoints } from '@hooks/useBreakpoints';

import { Box } from '@mui/material';
import { StyledWrapperContent } from '@components/AuthorizationLayout/styled';
import Logo from '@components/Logo';

import { StyledTypographyPrimaryContrast } from '@/shared/styles';

const AuthorizationLayout: FC<PropsWithChildren> = ({ children }) => {
  const { downMd } = useBreakpoints();
  const { t } = useTranslation();
  const location = useLocation();

  const isLoginPage = location.pathname.includes(ROUTING.LOGIN);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: downMd ? 'column' : 'row',
        gap: 0,
        minHeight: '100vh',
      }}
    >
      {/* Left side */}
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          flexBasis: '51.8%',
          paddingTop: {
            xs: '66px',
            md: '76px',
          },
          paddingLeft: {
            xs: '16px',
            sm: '21px',
            md: '30px',
            lg: '81px',
          },
          paddingRight: {
            xs: '16px',
            sm: '165px',
            md: '30px',
            lg: '120px',
          },
          paddingBottom: '160px',
          backgroundImage: {
            xs: 'url(./assets/images/phones.png)',
            md: 'none',
          },
          backgroundSize: {
            xs: 'contain',
            md: 'none',
          },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom 0px right',
          flexGrow: 1,
        }}
        minHeight={downMd ? '950px' : 'auto'} // to tackle small screen mobile view
      >
        <main aria-roledescription="authorization-container">
          <StyledWrapperContent>
            <Box
              mb={{
                xs: '49px',
                sm: '33px',
                md: '60px',
                lg: isLoginPage ? '90px' : '60px',
              }}
            >
              <Logo />
            </Box>
            {children}
          </StyledWrapperContent>
        </main>
      </Box>

      {/* Right side */}
      {!downMd && (
        <Box
          display="flex"
          flexDirection={downMd ? 'column' : 'row'}
          sx={{
            background: !downMd
              ? 'linear-gradient(to top,#FDC15E1F, #F5A57540, #ED898B80, #A354AFBF, #8435E9, #5A1ED3)'
              : '#FFF',
            flexBasis: '48.2%',
            height: downMd ? '100%' : 'auto',
            flexGrow: 1,
          }}
        >
          <Box
            height="100%"
            pt={{
              lg: '263px',
              md: '236px',
              sm: 0,
              xs: 0,
            }}
            flexGrow={1}
            display="flex"
            flexDirection="column"
            sx={{
              backgroundImage: {
                xs: 'none',
                md: 'url(./assets/images/phones.png)',
              },
              backgroundSize: {
                xs: 'none',
                md: 'contain',
              },
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom 0px right',
            }}
          >
            {!downMd && (
              <Box
                pl={{
                  lg: '61px',
                  md: '35px',
                }}
              >
                <StyledTypographyPrimaryContrast fontSize="32px" fontFamily="WFVisualSans" maxWidth={355}>
                  {t('auth.loginSecondaryTitle')}
                </StyledTypographyPrimaryContrast>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default memo(AuthorizationLayout);
