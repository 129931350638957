import { TextField, TextFieldProps, Typography, TypographyProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledTextField = styled(TextField)<TextFieldProps>({
  '& .MuiInputLabel-root': {
    fontWeight: 400,
  },
  '& .MuiInputBase-root': {
    fontWeight: 400,
  },
});

export const StyledTypography = styled(Typography)<TypographyProps & { theme?: Theme }>(({ theme }) => ({
  color: theme.colors.secondary.contrast,
}));
