import { ClipboardEvent, Dispatch, FC, memo, SetStateAction, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { CONFIRM_EMAIL_NUMBER_OF_DIGITS } from '@constants/auth';
import Countdown from '@pages/ConfirmEmail/components/Countdown';
import { StyledBox } from '@pages/styled';

import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Typography } from '@mui/material';
import RegistrationHeader from '@components/RegistrationHeader';

import { StyledDigitTextField, StyledPaper, StyledTextButton } from './styled';

const ConfirmEmailForm: FC<{
  loading: boolean;
  handleResend: () => void;
  isCodeSent: boolean;
  countdown: number;
  setCodeSent: Dispatch<SetStateAction<boolean>>;
  setCountdown: Dispatch<SetStateAction<number>>;
  myEmail?: string;
  isLoadingResend?: boolean;
}> = ({ loading, handleResend, isCodeSent, countdown, setCountdown, setCodeSent, myEmail, isLoadingResend }) => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  // @ts-ignore
  const hasInvalidCode = typeof errors?.code === 'object' && errors?.code?.type === 'not_found';
  const hasTooManyRequestsCode = typeof errors?.code === 'object' && errors?.code?.type === 'too_many';

  const handleCodeChange = (index: number, value: string) => {
    if (hasInvalidCode) {
      clearErrors();
    }
    const singleDigit = value.slice(-1);
    const currentValues = getValues('code') || [];

    currentValues[index] = singleDigit;
    setValue(`code[${index}]`, singleDigit, { shouldValidate: true });

    if (singleDigit && index < CONFIRM_EMAIL_NUMBER_OF_DIGITS - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    if (singleDigit === '' && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  // @ts-ignore
  const isMinLengthError =
    Array.isArray(errors?.code) && errors?.code?.some(obj => obj && typeof obj === 'object' && obj.type === 'min');
  const hasValidationErrors =
    // @ts-ignore
    Object.keys(errors).length > 0 && typeof errors?.code !== 'object' && errors?.code?.type !== 'not_found';

  const handlePaste = (e: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement | undefined>) => {
    if (hasInvalidCode) {
      clearErrors();
    }
    const pasteData = e.clipboardData.getData('text/plain');
    if (pasteData.length === CONFIRM_EMAIL_NUMBER_OF_DIGITS) {
      e.preventDefault();
      pasteData.split('').forEach((digit, idx) => {
        setValue(`code[${idx}]`, digit, { shouldValidate: true });
        if (inputRefs.current[idx]) {
          inputRefs.current[idx].value = digit;
        }
      });
      inputRefs.current[CONFIRM_EMAIL_NUMBER_OF_DIGITS - 1]?.focus();
    }
  };

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      flexDirection="column"
      aria-label="Confirm email form"
      maxWidth={{
        sm: '560px',
        md: '550px',
        lg: '505px',
      }}
    >
      <RegistrationHeader />
      <StyledPaper
        elevation={6}
        sx={{
          px: {
            xs: '25px',
            sm: '40px',
          },
          py: '40px',
        }}
      >
        <StyledBox>
          <Typography fontSize={20} fontFamily="WFVisualSans" mb={1}>
            {t('auth.confirmEmailTitle')}
          </Typography>
          <Trans
            i18nKey={t('auth.verificationCodeText')}
            values={{
              userEmail: myEmail || '',
            }}
            components={{
              regular: <Typography fontWeight={400} fontSize={16} mb="52px" />,
            }}
          />
          <Box width="100%" display="flex" gap={2.5} mb="18px" justifyContent="center">
            {[...Array(CONFIRM_EMAIL_NUMBER_OF_DIGITS)].map((_, index) => (
              <Controller
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                control={control}
                name={`code[${index}]`}
                defaultValue=""
                render={({ field: { onChange, ...field } }) => (
                  <StyledDigitTextField
                    {...field}
                    fullWidth
                    type="number"
                    variant="standard"
                    inputProps={{
                      maxLength: 1,
                      'aria-required': 'true',
                      'aria-label': `verification code field ${index + 1}`,
                      onPaste: handlePaste,
                    }}
                    slotProps={{
                      input: {
                        disableUnderline: true,
                      },
                    }}
                    onChange={e => {
                      onChange(e);
                      handleCodeChange(index, e.target.value);
                    }}
                    error={Boolean(errors.code)}
                    inputRef={el => (inputRefs.current[index] = el)}
                  />
                )}
              />
            ))}
          </Box>
          <Box height={28}>
            {/* @ts-ignore */}
            {isMinLengthError && (
              <Typography color="error" fontWeight={400} textAlign="center">
                {t(`auth.codeLengthError`, { defaultValue: errors?.code?.message })}
              </Typography>
            )}
            {/* @ts-ignore */}
            {errors.code?.type === 'not_found' && (
              <Typography color="error" fontWeight={400} textAlign="center">
                {t('auth.invalidCode', { defaultValue: errors.code.message })}
              </Typography>
            )}
            {hasTooManyRequestsCode && (
              <Box display="flex" justifyContent="center">
                <Typography
                  color="error"
                  fontWeight={400}
                  textAlign="center"
                  maxWidth={304}
                  style={{ textAlign: 'center' }}
                >
                  {t('auth.tooMany', { defaultValue: errors?.code?.message })}
                </Typography>
              </Box>
            )}
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" height="40px" gap={1} mb={3} mt={3}>
            {!isCodeSent && !hasTooManyRequestsCode && (
              <StyledTextButton
                onClick={handleResend}
                disabled={isCodeSent && !!countdown}
                aria-label="send again button"
                color="primary"
                variant="text"
              >
                {t('common.sendCodeAgain')}
              </StyledTextButton>
            )}
            {isCodeSent && !hasTooManyRequestsCode && Number(countdown) > 0 ? (
              <Countdown countdown={countdown} setCountdown={setCountdown} setCodeSent={setCodeSent} />
            ) : null}
          </Box>
          {!hasTooManyRequestsCode && (
            <LoadingButton
              fullWidth
              disabled={hasValidationErrors}
              variant="contained"
              color="primary"
              loading={loading}
              type="submit"
              aria-label="confirm button"
            >
              {t('common.continue')}
            </LoadingButton>
          )}
          {hasTooManyRequestsCode && (
            <LoadingButton
              fullWidth
              variant="contained"
              color="primary"
              loading={isLoadingResend}
              onClick={handleResend}
              aria-label="confirm button"
            >
              {t('common.sendCodeAgain')}
            </LoadingButton>
          )}
        </StyledBox>
      </StyledPaper>
    </Box>
  );
};

export default memo(ConfirmEmailForm);
