import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';

const InvoicesContext = createContext<ReturnType<typeof useInvoicesContextValue> | null>(null);

const useInvoicesContextValue = () => {
  const [open, setOpen] = useState(false);
  const [documentId, setDocumentId] = useState<number | null>(null);

  const toggleDrawer = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return {
    open,
    toggleDrawer,
    setDocumentId,
    documentId,
  };
};

export const useInvoicesContext = () => {
  const context = useContext(InvoicesContext);
  if (!context) throw new Error('useInvoicesContext must be inside InvoicesProvider');
  return context;
};

export const InvoicesProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useInvoicesContextValue();
  return <InvoicesContext.Provider value={value}>{children}</InvoicesContext.Provider>;
};
