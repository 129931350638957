import { FC, memo, PropsWithChildren } from 'react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { useSubscription } from '@hooks/useSubscription';
import TrialBar from '@providers/layouts/AppLayout/components/TrialBar';
import { StyledAppLayoutWrapper } from '@providers/layouts/AppLayout/styled';

import Box from '@mui/material/Box';

import Main from './components/Main';
import Navigation from './components/Navigation';

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const { isTrial, isLoadingSubscription, trialDaysLeft } = useSubscription();

  const { downMd } = useBreakpoints();
  if (isLoadingSubscription) return null;

  return (
    <StyledAppLayoutWrapper minHeight="100vh">
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        style={{
          // For mobile view
          position: downMd ? 'sticky' : 'inherit',
          top: downMd ? 0 : 'inherit',
          zIndex: 1600,
          backgroundColor: 'white',
        }}
      >
        <Navigation />
        {isTrial && <TrialBar daysLeft={trialDaysLeft} />}
      </Box>
      <Main>{children}</Main>
    </StyledAppLayoutWrapper>
  );
};

export default memo(AppLayout);
