import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Paper,
  PaperProps,
  TextField, TextFieldProps,
  Typography,
  TypographyProps
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledIdBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: '#F4ECFF',
  padding: '15px',
  textAlign: 'center',
  height: '54px',
  borderRadius: '4px',
  border: `1px solid ${theme.colors.primary.main}`,
}));

export const StyledEditBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  border: 'transparent',
  outline: 'transparent',
  boxShadow: 'none',
  color: theme.colors.primary.main,
  height: 30,
  width: 150,
  cursor: 'pointer',
}));

export const StyledCancelBtn = styled(Button)<ButtonProps>({
  fontWeight: 500,
  backgroundColor: 'transparent',
  paddingLeft: 0,
  boxShadow: 'none',
});

export const StyledBoxWrapper = styled(Box)<BoxProps>({
  '& .MuiFormControl-root .MuiFormHelperText-root': {
    display: 'none',
  },
});

export const StyledPaper = styled(Paper)<PaperProps & { theme?: Theme }>(() => ({
  borderRadius: '10px',
}));

export const StyledEmailIdTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.primary.dark,
}));

export const StyledCreateIdTextField = styled(TextField)<TextFieldProps>(({ theme, error }) => ({
  '& .MuiInputBase-input': {
    color: error ? theme.colors.error.main : 'currentColor'
  }
}))
