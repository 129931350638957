import { Dialog, DialogProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const mapSizes = {
  small: {
    '.MuiPaper-root': {
      maxWidth: '390px',
      width: '100%',
    },
  },
  medium: {
    '.MuiPaper-root': {
      maxWidth: '540px',
      width: '100%',
    },
  },
  xl: {
    '.MuiPaper-root': {
      maxWidth: '1080px',
      width: '100%',
    },
  },
};

export const StyledModal = styled(Dialog)<DialogProps & { size?: 'small' | 'medium' | 'xl' }>(({ size }) => ({
  zIndex: 1800,
  ...(size && mapSizes[size]),
}));

export const StyledTypographyContrast = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.secondary.contrast,
}));

export const StyledTypographyPrimaryDark = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.primary.dark,
}));

export const StyledTypographyPrimaryMain = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.primary.main,
}));

export const StyledTypographyPrimaryContrast = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.primary.contrast,
}));
